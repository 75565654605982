import {createContext, ReactNode, useState} from "react";

type Props = {
    children?: ReactNode;
}

type NavbarButton = {
    shown: boolean,
    setShown: (value: boolean) => void,
}

export const NavbarButtonContext = createContext<NavbarButton>({
    shown: true,
    setShown: () => {},
});

export default function NavbarButtonProvider(props: Props) {
    const [shown, setShown] = useState(true);

    return (
        <NavbarButtonContext.Provider value={{shown, setShown}}>
            {props.children}
        </NavbarButtonContext.Provider>
    );
}