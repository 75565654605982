import { env, getAuthToken } from "./tools";

/**
 * The application name
 * Used for setting the title
 */
export const APP_NAME = env("APP_NAME", "ViGo bioenergy");

/**
 * The API url
 */
export const API_URL = env("API_URL");

/**
 * The Mapbox public token
 */
export const MAPBOX_TOKEN = env("MAPBOX_TOKEN");

/**
 * Debounce timeout
 * See https://mantine.dev/hooks/use-debounced-value
 */
export const DEBOUNCE_TIMEOUT = 300;

/**
 * Get the default fetch headers.
 */
export const FETCH_HEADERS = () => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
};

export const NOTIFICATION_SETTING_EVENTS = [
  { label: "New bunker request", value: "new_request" },
  { label: "Updated bunker request", value: "updated_request" },
  { label: "Approved bunker request", value: "approved_request" },
  { label: "Rejected bunker request", value: "rejected_request" },
  { label: "Finished bunker request", value: "finished_request" },
  { label: "Cancelled request", value: "cancelled_request" },
];
