import LocationMap from "../components/LocationMap";
import React, {useEffect, useState} from "react";
import {fetchBunkerLocations} from "../api/bunkerLocations";
import {LoadingOverlay} from "@mantine/core";
import {ListSearch} from "tabler-icons-react";
import {useLocation, useNavigate} from "react-router-dom";

function LocationSearchOverlay() {
    const navigate = useNavigate()

    return (
        <div style={{
            width: "100%",
            display: "flex",
            position: "absolute",
            justifyContent: "right",
        }} >
            <div
                style={{position: "fixed", marginTop: 22, marginRight: 20, zIndex: 5}}
                onClick={() => navigate("/locations")}
            >
                <ListSearch size={28}/>
            </div>
        </div>
    );
}

export default function Home() {
    const [data, setData] = useState();
    const location = useLocation()

    const initialLocation = (location.state as any)?.location;

    const fetch = async function () {
        let data = await fetchBunkerLocations();

        if (!data?.data) {
            return;
        }

        setData(data.data);
    }

    useEffect(() => {
        fetch();
    }, []);

    return (
        <div style={{position: "relative", width: "100%", height: "100%", zIndex: 0}}>
            <LoadingOverlay visible={!data}/>

            <LocationSearchOverlay/>

            <LocationMap locations={data} initialLocation={initialLocation}/>
        </div>
    );
}