import {Route, Routes} from "react-router-dom";
import {ConditionalComponent} from "./ConditionalComponent";
import Home from "../../pages/Home";
import NotFound from "../../pages/NotFound";
import Login from "../../pages/Login";
import {useContext} from "react";
import {UserContext} from "../../providers/user";
import Me from "../../pages/Me";
import PasswordForgotten from "../../pages/PasswordForgotten";
import PasswordResetConfirmation from "../../pages/PasswordResetConfirmation";
import ResetPassword from "../../pages/ResetPassword";
import Locations from "../../pages/locations";
import BunkerRequests from "../../pages/bunker-requests";
import CreateBunkerRequest from "../../pages/bunker-requests/create";
import BunkerRequestConfirmation from "../../pages/bunker-requests/confirmation";
import Onboarding from "../../pages/Onboarding";
import VerifyEmail from "../../pages/VerifyEmail";
import Contact from "../../pages/Contact";
import Settings from "../../pages/settings";
import Notifications from "../../pages/Notifications";

export default function FullRouteCollection() {
    const {isAuthed, data} = useContext(UserContext);

    return isAuthed && data && !data.has_boarded ? (
        <Routes>
            <Route path={"*"} element={<ConditionalComponent condition={isAuthed} Component={Onboarding} title={"Home"} />} />
        </Routes>
    ) : (
        <Routes>
            <Route path={"/"} element={<ConditionalComponent condition={isAuthed} Component={Home} title={"Home"} />}/>

            <Route path={"/notifications"} element={<ConditionalComponent condition={isAuthed} Component={Notifications} title={"Notifications"} />} />
            <Route path={"/requests"} element={<ConditionalComponent condition={isAuthed} Component={BunkerRequests} title={"Requests"} />} />
            <Route path={"/locations"} element={<ConditionalComponent condition={isAuthed} Component={Locations} title={"Locations"} />} />
            <Route path={"/location/:id/request"} element={<ConditionalComponent condition={isAuthed} Component={CreateBunkerRequest} title={"Create request"} />} />
            <Route path={"/request/:id/confirmation"} element={<ConditionalComponent condition={isAuthed} Component={BunkerRequestConfirmation} title={"Request confirmation"} />} />

            <Route path={"/me"} element={<ConditionalComponent condition={isAuthed} Component={Me} title={"Profile"} />} />
            <Route path={"/contact"} element={<ConditionalComponent condition={isAuthed} Component={Contact} title={"Contact"} />} />
            <Route path={"/settings"} element={<ConditionalComponent condition={isAuthed} Component={Settings} />} />

            <Route path={"/login"} element={<ConditionalComponent condition={!isAuthed} redirectTo={"/"} Component={Login} title={"Login"} />} />
            <Route path={"/password-forgotten"} element={<ConditionalComponent condition={!isAuthed} redirectTo={"/"} Component={PasswordForgotten} title={"Password Forgotten"} />} />
            <Route path={"/password-reset-confirmed/:email"} element={<ConditionalComponent condition={!isAuthed} redirectTo={"/"} Component={PasswordResetConfirmation} title={"Password Reset Confirmed"} />} />
            <Route path={"/password-reset/:token/:email/:expires/:signature"} element={<ConditionalComponent condition={!isAuthed} redirectTo={"/"} Component={ResetPassword} title={"Reset Password"} />}/>

            <Route path={"/verify/:id/:hash/:expires/:signature"} element={<ConditionalComponent condition={!isAuthed} redirectTo={"/"} Component={VerifyEmail} title={"Set up account"} />}/>

            <Route path={"*"} element={<ConditionalComponent condition={isAuthed} redirectTo={"/login"} Component={NotFound} title={"404"} />} />
        </Routes>
    );
}