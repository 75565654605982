import {
  Anchor,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import PasswordForgottenForm from "../components/forms/PasswordForgottenForm";
import usePasswordForgotten from "../hooks/usePasswordForgotten";
import BrandHeader from "../components/headers/BrandHeader";

export default function PasswordForgotten() {
  const { sendResetPasswordRequest, loading } = usePasswordForgotten();

  return (
    <>
      <Group position="center">
        <BrandHeader showBackButton={true} backButtonUrl={"/login"} />
      </Group>

      <Stack justify="center" spacing={0} style={{ height: "75vh" }}>
        <Stack align="center" spacing={25}>
          <Stack align="center" spacing={0} m={10}>
            <Title order={2}>Password Reset</Title>
            <Text align="center">
              Enter your e-mail address below so we can send an e-mail to reset
              your password.
            </Text>
          </Stack>

          <Group
            position="center"
            grow
            style={{ width: "90%", maxWidth: "350px" }}
          >
            <div style={{ position: "relative" }}>
              <LoadingOverlay visible={loading} />
              <PasswordForgottenForm
                onSubmit={(values) => sendResetPasswordRequest(values)}
              />
            </div>
          </Group>
        </Stack>
      </Stack>

      <div style={{ position: "absolute", bottom: "0", left: "0", right: "0" }}>
        {/** TODO: get data from API */}
        <Text align="center" mb={20}>
          Contact us at{" "}
          <Anchor href="tel:+31 (0)70 318 0480">+31 (0)70 318 0480</Anchor> or{" "}
          <Anchor href="mailTo:bunkering@vigobioenergy.com">
            bunkering@vigobioenergy.com
          </Anchor>
          .
        </Text>
      </div>
    </>
  );
}
