import {Button, Center, Container, createStyles, Loader, Text, Title} from '@mantine/core'
import {Link, Navigate, useParams} from "react-router-dom"
import useBunkerRequestData from "../../hooks/bunkerRequest/useBunkerRequestData";
import dayjs from "dayjs";

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 60,
        paddingBottom: 20,
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

export default function BunkerRequestConfirmation() {
    const {classes} = useStyles();
    const {id: locationId} = useParams();
    const {status, data, isFetching, isError} = useBunkerRequestData(locationId);
    const isLoading = status === "loading" || isFetching;

    if ((!isLoading && !data) || isError) {
        return <Navigate replace to={"/"}/>
    }

    return (
        <Container className={classes.root}>
            <Container>
                <Title mb={"lg"}>All done!</Title>

                {isLoading ? (
                    <Center>
                        <Loader color="bright-green"/>
                    </Center>
                ) : (
                    <>
                        <Text>Thank you for your bunker request. We have reserved the
                            timeslot {data.time_slot.start_time} - {data.time_slot.end_time} hours
                            on {dayjs(data.date).format("DD-MM-YYYY")}. Feel free to
                            contact us if you have any questions.</Text>
                    </>
                )}

                <Button mt={"lg"} component={Link} to={"/"} fullWidth>Go to home</Button>
            </Container>
        </Container>
    );
}