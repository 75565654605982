import { Container, createStyles, Group, Stack, Text, Title, Button } from "@mantine/core";
import { fetchBunkerRequests } from "../../api/bunkerRequests";
import React, { useContext } from "react";
import dayjs from "dayjs";
import { formattedRequestStatus } from "../../tools";
import { BunkerRequest } from "../../../types";
import FancyList from "../../components/FancyList";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../providers/user";
import useCancelBunkerRequest from "../../hooks/bunkerRequest/useCancelBunkerRequest";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 60,
    paddingBottom: 20,
    height: "100%",
  },
}));

export default function BunkerRequests() {
  const { data } = useContext(UserContext);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { openModal: openCancelBunkerRequest } = useCancelBunkerRequest();

  const labelRenderer = (request: BunkerRequest) => {
    return (
      <Stack spacing={0}>
        <Text>{`${dayjs(request.date).format("DD-MM-YYYY")}`}</Text>
        <Text
          size="sm"
          color="dimmed"
          weight={400}
          style={!!request.cancelled_at ? { textDecoration: "line-through" } : {}}
        >
          {`${request.time_slot?.start_time} - ${request.time_slot?.end_time} – ${request.ship?.name} (${request.ship?.imo_number})`}
        </Text>
      </Stack>
    );
  };

  const detailsRenderer = (request: BunkerRequest) => {
    // Show the contact button if the request occurs within 24, if the request has been completed,
    // if the request has been cancelled, if the request has been rejected or
    // if the user id of the bunker request does not match the current user's id.
    const showContactButton =
      dayjs(new Date(`${request?.date} ${request?.time_slot?.start_time}` ?? "")).diff(
        new Date(),
        "hours"
      ) <= 24 ||
      !!request?.completed_at ||
      !!request?.cancelled_at ||
      (!request?.is_approved && !!request?.processed_at) ||
      request?.user_id !== data?.id;

    return (
      <>
        <Text size="sm">Customer: {request.ship?.customer?.name}</Text>
        <Group spacing={0}>
          <Text size="sm">Status:&nbsp;</Text>
          {formattedRequestStatus(request)}
        </Group>
        <Text size="sm">Expected amount: {request.expected_amount} kg</Text>
        <Text size="sm">
          Bunkered amount: {request.bunkered_amount ? `${request.bunkered_amount} kg` : "N/A"}
        </Text>

        <Group grow>
          {showContactButton ? (
            <Button mt={"sm"} size={"lg"} color="bright-green" onClick={() => navigate("/contact")}>
              Contact
            </Button>
          ) : (
            <Button
              mt={"sm"}
              size={"lg"}
              color="custom-red"
              onClick={() => openCancelBunkerRequest(request.id)}
            >
              Cancel request
            </Button>
          )}
        </Group>
      </>
    );
  };

  return (
    <Container className={classes.root}>
      <Title color={"bright-green"} mb={"lg"}>
        Bunker requests
      </Title>

      <FancyList<BunkerRequest>
        queryKey={"bunkerRequests"}
        queryFn={fetchBunkerRequests}
        detailsRenderer={detailsRenderer}
        labelRenderer={labelRenderer}
        querySortBy={[{ field: "created_at", order: "desc" }]}
      />
    </Container>
  );
}
