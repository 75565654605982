import {Accordion, Avatar, Container, Group, LoadingOverlay, Stack, Text} from "@mantine/core";
import React, {useContext} from "react";
import {UserContext} from "../providers/user";
import {Password, User} from "../../types";
import MeForm from "../components/forms/MeForm";
import ChangePasswordForm from "../components/forms/ChangePasswordForm";
import LogoutButton from "../components/buttons/LogoutButton";
import useMutateUserData from "../hooks/user/useMutateUserData";
import {updatePassword, updateUser} from "../api/users";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";

export default function Me() {
    const {data, loading: userContextLoading} = useContext(UserContext);
    const {mutate: mutateUser, isLoading: mutatingUser} = useMutateUserData(updateUser);

    const onPasswordSuccess = function() {
        showNotification({
            title: 'Password changed',
            icon: <Check/>,
            message: `Your password has been changed.`,
        })
    }

    const onPasswordError = function (error: string) {
        showNotification({
            title: `Something went wrong`,
            message: `An error occurred while changing your password. ${error}`,
            color: 'red'
        })
    }

    const {mutate: mutatePassword, isLoading: mutatingPassword} = useMutateUserData(updatePassword, onPasswordSuccess, onPasswordError);

    const onSubmitUser = function (user: User) {
        mutateUser(user);
    }

    const onSubmitPassword = function (password: Password) {
        mutatePassword(password);
    }

    return !data ? (
        <div style={{position: "relative", width: "100%", height: "100%"}}>
            <LoadingOverlay visible={true}/>
        </div>
    ) : (
        <Stack align={"stretch"} spacing={0}>
            <Group position="center" style={{height: "20vh", maxHeight: "200px", backgroundColor: "#69B365"}}>
                <Avatar
                    radius="xl"
                    size="lg"
                    src={null}
                    alt="Profile picture"
                />

                <Text
                    color={"white"}
                    weight={700}
                    size="xl"
                >
                    {data?.name}
                </Text>
            </Group>

            <Accordion
                chevronPosition="left"
                chevronSize={40}
                defaultValue={"account-details"}
                styles={{
                    control: {
                        color: "#289323",
                    },
                    panel: {
                        color: "#289323"
                    },
                    chevron: {
                        color: '#289323',
                        transform: 'scale(1.3)',
                        '&[data-rotate]': {
                          transform: 'scale(1.3) rotate(180deg)',
                        }
                      }
                }}
            >
                <Accordion.Item value={"account-details"}>
                    <Accordion.Control>Account details</Accordion.Control>
                    <Accordion.Panel>
                        <Container>
                            <MeForm user={data} onSubmit={onSubmitUser} loading={mutatingUser || userContextLoading}/>
                        </Container>
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={"change-password"}>
                    <Accordion.Control>Change password</Accordion.Control>
                    <Accordion.Panel>
                        <Container>
                            <ChangePasswordForm onSubmit={onSubmitPassword} loading={mutatingPassword}/>
                        </Container>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Group position="center" grow style={{margin: "30px"}}>
                <LogoutButton/>
            </Group>
        </Stack>
    );
}