import { BunkerRequest, Maybe, SortingRule } from "../types";
import { Text } from "@mantine/core";
import dayjs from "dayjs";

/**
 * Get a environment variable.
 */
export function env(name: string, defaultValue: string = "") {
  return process.env[`REACT_APP_${name}`] || defaultValue;
}

/**
 * Test if localstorage has a token.
 */
export function hasAuthToken() {
  return Boolean(getAuthToken());
}

/**
 * Test if localstorage has a token.
 */
export function getAuthToken() {
  return localStorage.getItem("token");
}

/**
 * Set the authentication token in the local storage.
 */
export function setAuthToken(token: string) {
  localStorage.setItem("token", token);
}

/**
 * Remove authorization token from the local storage.
 */
export function removeAuthToken(): void {
  localStorage.removeItem("token");
}

export function isEmptyArray(input: Maybe<any[]>) {
  return Array.isArray(input) && !input.length;
}

export function isArray(input: Maybe<any[]>) {
  return Array.isArray(input);
}

/**
 * Format errors from backend
 */
export function formatErrors(response: any) {
  if (response?.errors?.stacktrace) {
    delete response.errors.stacktrace;
  }

  let messages: Array<string> = [];

  response.errors && response.message && messages.push(response.message);

  response.errors &&
    Object.values(response.errors).map(
      (error: any) => !messages.includes(error) && messages.push(error)
    );

  response.error && messages.push(response.error);

  return messages;
}

export async function handleRequestErrors(response: Response) {
  if (!response.ok) {
    let responseClone = response.clone();
    let errors;

    try {
      errors = formatErrors(JSON.parse(await responseClone.text()));
    } catch (err) {
      throw new Error(response.statusText);
    }

    if (!isEmptyArray(errors)) {
      throw new Error(errors[0]);
    }

    throw new Error("An unknown error occurred.");
  }

  return response;
}

export function formattedRequestStatus(bunkerRequest?: BunkerRequest) {
  if (!bunkerRequest) return <Text size={"sm"}>N/A</Text>;

  if (bunkerRequest.completed_at)
    return (
      <Text size={"sm"} color={"#289323"}>
        Completed
      </Text>
    );

  if (bunkerRequest.cancelled_at)
    return (
      <Text size={"sm"} inline color={"#C5043F"}>
        Cancelled
      </Text>
    );
    
  if (bunkerRequest.is_approved)
    return (
      <Text size={"sm"} color={"#289323"}>
        Approved
      </Text>
    );

  return bunkerRequest.processed_at ? (
    <Text size={"sm"} color={"#C5043F"}>
      Rejected
    </Text>
  ) : (
    <Text size={"sm"}>Pending</Text>
  );
}

export function humanizeTime(date: Maybe<Date> = null) {
  var relativeTime = require("dayjs/plugin/relativeTime");
  dayjs.extend(relativeTime);

  // @ts-ignore
  return dayjs().to(date);
}

export function generateSortString(sort: SortingRule[]) {
  let columns = sort
    .map((s: SortingRule) => `${s.order === "desc" ? "-" : ""}${s.field}`)
    .join(",");

  return `&sort=${columns}`;
}
