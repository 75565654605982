import {Container, LoadingOverlay, Title, Text} from "@mantine/core";
import NotificationSettingsForm from "../../components/forms/NotificationSettingsForm";
import React, {useContext} from "react";
import {UserContext} from "../../providers/user";
import {updateSettings} from "../../api/users";
import useMutateUserData from "../../hooks/user/useMutateUserData";
import {ProfileSettings} from "../../../types";
import {useModals} from "@mantine/modals";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";

export default function Settings() {
    const {data} = useContext(UserContext);
    const modals = useModals();

    const onSuccess = function() {
        showNotification({
            title: 'Settings updated',
            icon: <Check/>,
            message: `Your settings have been updated!`,
        });
    }

    const onError = function(error: string) {
        showNotification({
            title: `Something went wrong`,
            message: `An error occurred while updating your settings. ${error}`,
            color: 'red'
        });
    }

    const {mutate, isLoading} = useMutateUserData(updateSettings, onSuccess, onError);

    const onSubmit = function(data: ProfileSettings) {
        if (data.notification_settings && data.notification_settings.length < 1) {
            modals.openConfirmModal({
                title: 'Are you sure?',
                children: <Text>You will not receive any notification and you can therefore miss out on important information.</Text>,
                labels: {confirm: 'Confirm', cancel: 'Cancel'},
                onConfirm() { mutate(data); },
            });

            return;
        }

        mutate(data);
    }

    return !data ? (
        <div style={{position: "relative", width: "100%", height: "100%"}}>
            <LoadingOverlay visible={true}/>
        </div>
    ) : (
        <Container size="xl" mt={78}>
            <Title mb={"2vh"}>Settings</Title>

            <Title order={3} mb={"1vh"}>Notification settings</Title>
            <NotificationSettingsForm user={data} onSubmit={onSubmit} loading={isLoading} />
        </Container>
    );
}