import {useForm} from "@mantine/form";
import {Button, Stack, TextInput} from "@mantine/core";
import {PasswordForgotten} from "../../../types";
import {validateEmail} from "../../validator";

type PasswordForgottenFormProps = {
    onSubmit: (value: PasswordForgotten) => void,
}

export default function PasswordForgottenForm(props: PasswordForgottenFormProps) {
    const {onSubmit} = props;

    const form = useForm<PasswordForgotten>({
        initialValues: {
            email: '',
        },
        validate: {
            email: (value) => validateEmail(value),
        }
    });

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack align="stretch">
                <TextInput
                    placeholder="Your email address"
                    type="email"
                    size="md"
                    required
                    styles={(theme) => ({
                        input: {
                            '&::placeholder': {
                                color: theme.colors['custom-gray'][8],
                            },
                            borderColor: theme.colors['custom-gray'][7],
                            '&:focus': {
                              borderColor: theme.colors['bright-green'][0],
                            },
                            color: '#484948',
                        },
                      })}
                    icon={<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.637"
                        height="15.637"
                        viewBox="0 0 15.637 15.637"
                      >
                        <path fill="none" d="M0 0h15.637v15.637H0z" data-name="Path 280"></path>
                        <path
                          fill="none"
                          stroke="#d4d8dd"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M1.38 3.877A1.509 1.509 0 012.811 2.3h10.016a1.509 1.509 0 011.431 1.577v7.884a1.509 1.509 0 01-1.431 1.577H2.811a1.509 1.509 0 01-1.431-1.577z"
                          data-name="Path 281"
                        ></path>
                        <path
                          fill="none"
                          stroke="#d4d8dd"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M1.38 5.008l6.439 2.76 6.439-2.76"
                          data-name="Path 282"
                        ></path>
                      </svg>}
                    {...form.getInputProps('email')}
                />

                <Button type="submit">Request password reset</Button>
            </Stack>
        </form>
    );
}