import {FETCH_HEADERS, API_URL} from "../constants";

export const fetchBunkerLocations = async (limit: number = Number.MAX_SAFE_INTEGER) => {
    return await fetch(`${API_URL}/location?page=${1}&limit=${limit}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchBunkerLocation = async (locationId: string) => {
    return await fetch(`${API_URL}/location/${locationId}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};
