import React, {useContext} from 'react';
import {AppShell} from "@mantine/core";
import AppFooter from "./components/AppShell/AppFooter";
import AppNavbar from "./components/AppShell/AppNavbar";
import AppHeader from "./components/AppShell/AppHeader";
import FullRouteCollection from "./components/routing/FullRouteCollection";
import {UserContext} from "./providers/user";
import {useLocation} from "react-router-dom";

/**
 * Determine if a route is either /login or /verify* (* as in wildcard)
 */
function isAuthRoute(pathname: string) {
    return [new RegExp("\\/login"), new RegExp("\\/verify/*"), new RegExp("\\/password-*")].some(
        (route) => !!pathname.match(route)
    );
}

export default function App() {
    const {isAuthed} = useContext(UserContext);
    const {pathname} = useLocation();

    return !isAuthed || isAuthRoute(pathname) ? (
        <FullRouteCollection />
    ) : (
        <AppShell
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            fixed
            padding={0}
            header={<AppHeader/>}
            navbar={<AppNavbar/>}
            footer={<AppFooter/>}
        >
            <FullRouteCollection />
        </AppShell>
    );
}
