import {Anchor, Card, Group, Stack, Text} from "@mantine/core";
import {Icon} from "tabler-icons-react";

type BunkerLocationDetailCardProps = {
    Icon: Icon,
    title: string,
    titleHref: string,
    text: string,
}

export default function BunkerLocationDetailCard(props: BunkerLocationDetailCardProps) {
    const {Icon, title, titleHref, text} = props;

    return (
        <Card p={5} radius="md">
            <Group>
                <Icon size={32} color="#484948" style={{marginLeft: "10px"}} />

                <Stack spacing={0}>
                    <Anchor href={`${titleHref}:${title}`}>{title}</Anchor>
                    <Text weight={400} color="#484948">{text}</Text>
                </Stack>
            </Group>
        </Card>
    );
}