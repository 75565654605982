import {Center, Image, Stack, Text, Title} from "@mantine/core";
import {Carousel} from "@mantine/carousel";

type OnboardingSlideProps = {
    imageUrl: string,
    title: string,
    text: string,
}

export default function OnboardingSlide(props: OnboardingSlideProps) {
    const {imageUrl, title, text} = props;

    return (
        <Carousel.Slide>
            <Center>
                <Image
                    src={imageUrl}
                    height={"30vh"}
                    radius="xl"
                    withPlaceholder
                    sx={(theme) => ({
                        width: "55vw",

                        [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
                            width: "100%",
                        },
                    })}
                />
            </Center>

            <Stack m={20}>
                <Title>{title}</Title>
                <Text>{text}</Text>
            </Stack>
        </Carousel.Slide>
    );
}