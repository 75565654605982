import {Burger} from "@mantine/core";
import {useContext, useEffect, useState} from "react";
import {NavbarContext} from "../../providers/navbar";
import {NavbarButtonContext} from "../../providers/navbarButton";

export default function AppHeader() {
    const [opened, setOpened] = useState(false);
    const {shown: navbarShown, setShown: setNavbarShown} = useContext(NavbarContext);
    const {shown: navbarButtonShown} = useContext(NavbarButtonContext);

    const onClick = function() {
        setNavbarShown(!opened);
        setOpened(!opened);
    }

    /**
     * The navbar can be closed from multiple locations.
     * Set therefore the burger menu state to closed when the navbarShown state is updated to closed.
     */
    useEffect(() => {
        if (!navbarShown) {
            setOpened(false);
        }
    }, [navbarShown]);

    return (
        <div style={{position: "fixed", top: "20px", left: "10px", zIndex: '3'}}>
            <Burger
                size={20}
                color={opened ? "white" : "#484948"}
                opened={opened}
                onClick={onClick}
                hidden={!navbarButtonShown}
            />
        </div>
    );
}