import {useContext, useState} from "react";
import {API_URL, FETCH_HEADERS} from "../constants";
import {UserContext} from "../providers/user";
import {useNavigate} from "react-router-dom";
import {isArray, isEmptyArray, setAuthToken} from "../tools";
import {showNotification} from "@mantine/notifications";

type LoginProps = {
    email: string,
    password: string,
}

type LoginValues = {
    email: string,
    password: string,
    device_name: string,
}

export default function useLogin() {
    const {refetch} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchData = async function(loginValues: LoginValues) {
        return await fetch(`${API_URL}/auth/login`, {
            method: "POST",
            ...FETCH_HEADERS(),
            body: JSON.stringify(loginValues)
        }).then((res) => res.json());
    };

    /**
     * Login the user.
     */
    const login = async function(loginProps: LoginProps) {
        setLoading(true);

        const res = await fetchData({...loginProps, device_name: window.navigator.userAgent});

        if (res?.data) {
            setAuthToken(res.data.token);

            refetch();
            navigate('/');

            setLoading(false);
            return;
        }

        showNotification({
            title: `Something went wrong`,
            message: (res?.errors && isArray(res?.errors) && !isEmptyArray(res?.errors)) ? res?.errors[0] : 'The email or password is incorrect.',
            color: 'red'
        });

        setLoading(false);
    }

    return {
        login,
        loading,
    };
}