import {Button} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {Icon} from "tabler-icons-react";
import {useContext} from "react";
import {NavbarContext} from "../../providers/navbar";

type NavbarButtonProps = {
    text: string,
    actionLink: string,
    Icon: Icon,
}

export default function NavbarButton(props: NavbarButtonProps) {
    const {text, actionLink, Icon} = props;
    const navigate = useNavigate();
    const {setShown: setNavbarShown} = useContext(NavbarContext);

    const onClick = function() {
        navigate(actionLink);
        setNavbarShown(false);
    }

    return (
        <Button
            variant="subtle"
            onClick={onClick}
            leftIcon={<Icon size={34} />}
            styles={(theme) => ({
                root: {
                    paddingLeft: 5,
                    paddingRight: 10,
                    color: "#289323",
                    '&:hover': {
                        backgroundColor: "#F5F5F5"
                    }
                },
                leftIcon: {
                    marginRight: 25,
                    color: "#d0d4d9",
                }
            })}
        >
            {text}
        </Button>
    );
}