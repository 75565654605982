import { Carousel, Embla } from "@mantine/carousel";
import { Group, Text, UnstyledButton } from "@mantine/core";
import { useEffect, useState } from "react";
import { ArrowRight, Check } from "tabler-icons-react";
import useOnboarding from "../../hooks/useOnboarding";
import OnboardingSlide from "../slides/OnboardingSlide";

const slides = [
  {
    imageUrl: "/onboarding/icon_one.jpeg",
    title: "Bunker App",
    text: "With the ViGo bioenergy BunkeringTool you can easily book your timeslot and manage your ship information.",
  },
  {
    imageUrl: "/onboarding/icon_two.jpeg",
    title: "Quick & Easy",
    text: "When booking your timeslot, all parties will be notified and you will receive all notifications automatically.",
  },
  {
    imageUrl: "/onboarding/icon_three.jpeg",
    title: "Even More!",
    text: "Fill in all ship information including contact details and keep this up to date to save time in communication with the staff on site.",
  },
];

export default function OnboardingCarousel() {
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
  const [emblaInstance, setEmblaInstance] = useState<Embla>();
  const [showFinishButton, setShowFinishButton] = useState(false);
  const { boardingCompleted } = useOnboarding();

  /**
   * Event is triggered when the button on the bottom right has been pressed.
   * Navigate away if all slides have been shown or update the current slide number and change the button if needed.
   */
  const onNextButtonClick = async function () {
    if (!emblaInstance?.canScrollNext()) {
      await boardingCompleted();
      return;
    }

    setCurrentSlideNumber(currentSlideNumber + 1);
    emblaInstance?.scrollNext();
    changeButton();
  };

  /**
   * Event is triggered when the slide that is shown has been changed.
   * Update the current slide number and change the button if needed.
   */
  const onScroll = function () {
    setCurrentSlideNumber(emblaInstance?.selectedScrollSnap() ?? 0);
    changeButton();
  };

  /**
   * Change the 'Next' button to the 'Finish' button if the last page is shown.
   */
  const changeButton = function () {
    emblaInstance?.canScrollNext()
      ? setShowFinishButton(false)
      : setShowFinishButton(true);
  };

  /**
   * Subscribe on carousel event(s) on mount.
   */
  useEffect(() => {
    if (emblaInstance) {
      emblaInstance.on("select", onScroll);
    }
  }, [emblaInstance]);

  return (
    <>
      <Carousel
        align="center"
        slideGap="xl"
        withControls={false}
        getEmblaApi={setEmblaInstance}
      >
        {slides.map((slide) => (
          <OnboardingSlide
            imageUrl={slide.imageUrl}
            title={slide.title}
            text={slide.text}
            key={slide.imageUrl}
          />
        ))}
      </Carousel>

      <Group position="right" mr={15}>
        <Text weight={700} size="lg">
          {currentSlideNumber + 1}/{slides.length}
        </Text>

        <UnstyledButton
          onClick={onNextButtonClick}
          sx={(theme) => ({
            borderRadius: "50%",
            backgroundColor: showFinishButton ? "#C5043F" : "#289323",
            padding: "15px",

            "&:active": {
              backgroundColor: showFinishButton ? "#A70637" : "#1C7B18",
            },
          })}
        >
          {showFinishButton ? (
            <Check size={40} color="white" />
          ) : (
            <ArrowRight size={40} color="white" />
          )}
        </UnstyledButton>
      </Group>
    </>
  );
}
