import {createContext, ReactNode} from "react";
import {Maybe, User} from "../../types";
import useUser from "../hooks/useUser";
import {FullPageSpinner} from "../components/FullPageSpinner";

type Props = {
    children?: ReactNode,
}

type UserProps = {
    data: Maybe<User>;
    isAuthed: boolean;
    loading: boolean;
    initialFetch: boolean;
    refetch: () => void;
}

export const UserContext = createContext<UserProps>({
    data: undefined,
    isAuthed: false,
    loading: false,
    initialFetch: false,
    refetch: () => {
    },
});

export default function UserProvider(props: Props) {
    const {data, isAuthed, refetch, loading, initialFetch} = useUser();

    return (initialFetch ? (
        <FullPageSpinner/>
    ) : (
        <UserContext.Provider value={{data, isAuthed, loading, initialFetch, refetch}}>
            {props.children}
        </UserContext.Provider>
    ))
}