import {Password, ProfileSettings, User} from "../../types";
import {API_URL, FETCH_HEADERS} from "../constants";
import {handleRequestErrors} from "../tools";

export async function updateUser(user: User) {
    return await fetch(`${API_URL}/auth/profile`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(user)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updateSettings(profileSettings: ProfileSettings) {
    return await fetch(`${API_URL}/auth/settings`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(profileSettings)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updatePassword(values: Password) {
    return await fetch(`${API_URL}/auth/change-password`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(values)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}