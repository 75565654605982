import {Button, PasswordInput, Stack} from "@mantine/core";
import {useForm} from "@mantine/form";
import {Password} from "../../../types";
import {validateSame, validateString} from "../../validator";

type ResetPasswordFormProps = {
    onSubmit: (values: Password) => void,
}

export default function ResetPasswordForm(props: ResetPasswordFormProps) {
    const {onSubmit} = props;

    const form = useForm<Password>({
        initialValues: {
            password: '',
            password_confirmation: '',
        },
        validate: (values) => ({
            password: validateString(values.password),
            password_confirmation: validateSame(values.password, values.password_confirmation, "the password field"),
        }),
    })

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack>
                <PasswordInput
                    size="md"
                    label="Password"
                    placeholder="Password"
                    required
                    styles={(theme) => ({
                        withIcon: {
                          borderColor: theme.colors['custom-gray'][7],
                            '&:has(input:focus)': {
                              borderColor: theme.colors['bright-green'][0],
                            },
                        },
                        input: {
                            '&::placeholder': {
                                color: theme.colors['custom-gray'][8],
                            }
                        },
                        visibilityToggle: {
                            color: theme.colors['custom-gray'][8],
                        },
                        innerInput: {
                            color: "#484948",
                        }
                      })}
                    icon={<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 16.596 16.596"
                      >
                        <path fill="none" d="M0 0h16.6v16.6H0z" data-name="Path 293"></path>
                        <path
                          fill="none"
                          stroke="#d5d9de"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M3.457 8.989A1.383 1.383 0 014.84 7.606h6.917a1.383 1.383 0 011.383 1.383v4.149a1.383 1.383 0 01-1.383 1.383H4.84a1.383 1.383 0 01-1.383-1.383z"
                          data-name="Path 294"
                        ></path>
                        <path
                          fill="none"
                          stroke="#d5d9de"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M7.606 11.063a.691.691 0 10.691-.691.691.691 0 00-.691.691"
                          data-name="Path 295"
                        ></path>
                        <path
                          fill="none"
                          stroke="#d5d9de"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M5.532 7.606V4.84a2.766 2.766 0 115.532 0v2.766"
                          data-name="Path 296"
                        ></path>
                      </svg>}
                        visibilityToggleIcon={({ reveal, size }) =>
                          reveal ? <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.414"
                            height="13.414"
                            viewBox="0 0 13.414 13.414"
                          >
                            <path
                              fill="none"
                              stroke="#b0b5bb"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                              d="M9.947 9.947a5.493 5.493 0 01-3.24 1.124c-3.818 0-6-4.364-6-4.364a10.064 10.064 0 012.76-3.24m2.095-.993a4.975 4.975 0 011.145-.131c3.818 0 6 4.364 6 4.364a10.091 10.091 0 01-1.178 1.74m-3.666-.584a1.636 1.636 0 11-2.312-2.312M.707.707l12 12"
                              data-name="Icon feather-eye-off"
                            ></path>
                          </svg> : <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.391"
                          height="16.391"
                          viewBox="0 0 16.391 16.391"
                        >
                          <path fill="none" d="M0 0h16.391v16.391H0z" data-name="Path 297"></path>
                          <path
                            fill="none"
                            stroke="#b0b5bb"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M6.83 8.196A1.366 1.366 0 108.196 6.83 1.366 1.366 0 006.83 8.196"
                            data-name="Path 298"
                          ></path>
                          <path
                            fill="none"
                            stroke="#b0b5bb"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M14.342 8.198q-2.459 4.1-6.147 4.1t-6.146-4.1q2.459-4.1 6.147-4.1t6.147 4.1"
                            data-name="Path 299"
                          ></path>
                        </svg>
                        }
                    {...form.getInputProps('password')}
                    
                />

                <PasswordInput
                    size="md"
                    label="Confirm password"
                    placeholder="Your password again"
                    required
                    styles={(theme) => ({
                        withIcon: {
                          borderColor: theme.colors['custom-gray'][7],
                            '&:has(input:focus)': {
                              borderColor: theme.colors['bright-green'][0],
                            },
                        },
                        input: {
                            '&::placeholder': {
                                color: theme.colors['custom-gray'][8],
                            }
                        },
                        visibilityToggle: {
                            color: theme.colors['custom-gray'][8],
                        },
                        innerInput: {
                            color: "#484948",
                        }
                      })}
                    icon={<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 16.596 16.596"
                      >
                        <path fill="none" d="M0 0h16.6v16.6H0z" data-name="Path 293"></path>
                        <path
                          fill="none"
                          stroke="#d5d9de"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M3.457 8.989A1.383 1.383 0 014.84 7.606h6.917a1.383 1.383 0 011.383 1.383v4.149a1.383 1.383 0 01-1.383 1.383H4.84a1.383 1.383 0 01-1.383-1.383z"
                          data-name="Path 294"
                        ></path>
                        <path
                          fill="none"
                          stroke="#d5d9de"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M7.606 11.063a.691.691 0 10.691-.691.691.691 0 00-.691.691"
                          data-name="Path 295"
                        ></path>
                        <path
                          fill="none"
                          stroke="#d5d9de"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M5.532 7.606V4.84a2.766 2.766 0 115.532 0v2.766"
                          data-name="Path 296"
                        ></path>
                      </svg>}
                        visibilityToggleIcon={({ reveal, size }) =>
                          reveal ? <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.414"
                            height="13.414"
                            viewBox="0 0 13.414 13.414"
                          >
                            <path
                              fill="none"
                              stroke="#b0b5bb"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                              d="M9.947 9.947a5.493 5.493 0 01-3.24 1.124c-3.818 0-6-4.364-6-4.364a10.064 10.064 0 012.76-3.24m2.095-.993a4.975 4.975 0 011.145-.131c3.818 0 6 4.364 6 4.364a10.091 10.091 0 01-1.178 1.74m-3.666-.584a1.636 1.636 0 11-2.312-2.312M.707.707l12 12"
                              data-name="Icon feather-eye-off"
                            ></path>
                          </svg> : <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.391"
                          height="16.391"
                          viewBox="0 0 16.391 16.391"
                        >
                          <path fill="none" d="M0 0h16.391v16.391H0z" data-name="Path 297"></path>
                          <path
                            fill="none"
                            stroke="#b0b5bb"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M6.83 8.196A1.366 1.366 0 108.196 6.83 1.366 1.366 0 006.83 8.196"
                            data-name="Path 298"
                          ></path>
                          <path
                            fill="none"
                            stroke="#b0b5bb"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M14.342 8.198q-2.459 4.1-6.147 4.1t-6.146-4.1q2.459-4.1 6.147-4.1t6.147 4.1"
                            data-name="Path 299"
                          ></path>
                        </svg>
                        }
                    {...form.getInputProps('password_confirmation')}
                />

                <Button type="submit">Change password</Button>
            </Stack>
        </form>
    );
}