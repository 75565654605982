import {Button} from "@mantine/core";
import useLogout from "../../hooks/useLogout";

export default function LogoutButton(props: any) {
    const {logout} = useLogout();

    return (
        <Button
            size="lg"
            onClick={() => logout()}
            styles={{root: {backgroundColor: "#C5043F", maxWidth: "500px", height: "55px", '&:hover': {backgroundColor: "#A70637"}}}}
            {...props}
        >
            Logout
        </Button>
    );
}