import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { Check } from "tabler-icons-react";
import { deleteBunkerRequest } from "../../api/bunkerRequests";
import CancelBunkerRequestForm from "../../components/forms/CancelBunkerRequestForm";
import useMutateBunkerRequestData from "./useMutateBunkerRequestData";

export default function useCancelBunkerRequest() {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Bunker request cancelled",
      icon: <Check />,
      message: `The bunker request has been cancelled!`,
    });
  };

  const onError = (error: string) => {
    showNotification({
      title: `Something went wrong`,
      message: `An error occurred while cancelling the bunker request. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateBunkerRequestData(deleteBunkerRequest, onSuccess, onError);

  const onSubmit = async (modalId: string, id: number | string, reason: string) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Updating bunker request",
      message: "Cancelling the bunker request...",
    });

    await mutate({ id: id, reason: reason });
  };

  const openModal = (id: string) => {
    const modalId = modals.openModal({
      centered: true,
      title: "Are you sure?",
      children: (
        <CancelBunkerRequestForm
          onClose={() => modals.closeModal(modalId)}
          onSubmit={(reason: string) => onSubmit(modalId, id, reason)}
        />
      ),
    });
  };

  return {
    openModal,
  };
}
