import Map, {GeolocateControl, GeolocateControlRef, NavigationControl} from "react-map-gl";
import {MAPBOX_TOKEN} from "../constants";
import "mapbox-gl/dist/mapbox-gl.css";
import React, {useState} from "react";
import {Location} from "../../types";
import BunkerLocationMarker from "./markers/BunkerLocationMarker";
import BunkerLocationDetailsDrawer from "./drawers/BunkerLocationDetailsDrawer";
import mapboxgl from "mapbox-gl";

// See https://github.com/visgl/react-map-gl/issues/1266#issuecomment-771121904 to know why this is needed.
/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

type LocationMapProps = {
    initialLocation?: Location,
    locations?: any[],
}

export default function LocationMap(props: LocationMapProps) {
    const {initialLocation, locations} = props;
    const [bunkerLocationId, setBunkerLocationId] = useState('');
    const [showDetails, setShowDetails] = useState(false);

    const onMarkerClick = function (location: Location) {
        setBunkerLocationId(location.id);
        setShowDetails(true);
    }

    /**
     * Execute on mount.
     */
    const geoLocateControlRef = React.useCallback((ref: GeolocateControlRef) => {
        if (ref && !initialLocation) {
            // Go to the location of the user when the control is loaded.
            ref.trigger();
        }
    }, []);

    const initialViewState = initialLocation ? {
        longitude: initialLocation.lon,
        latitude: initialLocation.lat,
        zoom: 10,
    } : {
        longitude: 5.21,
        latitude: 52.22,
        zoom: 6,
    }

    return (
        <>
            <Map
                initialViewState={initialViewState}
                style={{width: '100%', height: '100%'}}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                mapboxAccessToken={MAPBOX_TOKEN}
            >
                <NavigationControl position={"bottom-right"} showCompass={false}/>

                <GeolocateControl
                    position={"bottom-right"}
                    style={{background: "none", boxShadow: "none"}}
                    fitBoundsOptions={{maxZoom: 8}}
                    ref={geoLocateControlRef}
                />

                {locations?.map((location) =>
                    <BunkerLocationMarker key={location.id} bunkerLocation={location} onClick={onMarkerClick}/>)}
            </Map>

            <BunkerLocationDetailsDrawer
                bunkerLocationId={bunkerLocationId}
                opened={showDetails}
                onClose={() => setShowDetails(false)}
            />
        </>
    );
}