import {useQuery} from 'react-query'
import {fetchTimeSlot} from "../../api/timeSlots";

export default function useTimeSlotData(id?: string, onSuccess?: any, onError?: any) {
    return useQuery(['timeSlots'], () => fetchTimeSlot(id),
        {
            onSuccess,
            onError,
            keepPreviousData: false,
        }
    );
}
