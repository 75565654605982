import {useForm} from "@mantine/form";
import {User} from "../../../types";
import {Button, LoadingOverlay, Stack, TextInput} from "@mantine/core";
import {Mail, Phone, UserCircle} from "tabler-icons-react";
import {validateEmail, validateString} from "../../validator";

type MeFormProps = {
    user: User,
    onSubmit: (values: User) => void,
    loading: boolean,
}

export default function MeForm(props: MeFormProps) {
    const {user, onSubmit, loading} = props;

    const form = useForm<User>({
        initialValues: {
            id: user.id,
            name: user.name,
            email: user.email,
            email_verified: user.email_verified,
            active: user.active,
            phone: user.phone,
        },
        validate: {
            id: (value) => validateString(value),
            name: (value) => validateString(value),
            email: (value) => validateEmail(value),
            phone: (value) => validateString(value),
        }
    });

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <div style={{position: "relative"}}>
                <LoadingOverlay visible={loading} />
                <Stack align={"stretch"} spacing={10} m="0px 20px 20px 20px">
                    <TextInput
                        size="md"
                        label="Name"
                        placeholder="Your name"
                        required
                        icon={<UserCircle size={16} />}
                        {...form.getInputProps('name')}
                        styles={(theme) => ({
                            input: {
                                '&::placeholder': {
                                    color: theme.colors['custom-gray'][8],
                                },
                                borderColor: theme.colors['custom-gray'][7],
                                '&:focus': {
                                  borderColor: theme.colors['bright-green'][0],
                                },
                                color: '#484948',
                            },
                            label: {
                                color: "#484948",
                            },
                            icon: {
                                color: "#d5d9de",
                            },
                            required:{
                                color: "#004F65"
                            }
                        })}
                    />

                    <TextInput
                        size="md"
                        label="E-mail address"
                        description="Once changed, an e-mail to your new e-mail address will be sent for verification."
                        placeholder="your@mail.com"
                        required
                        type="email"
                        icon={<Mail size={16} />}
                        {...form.getInputProps('email')}
                        styles={(theme) => ({
                            input: {
                                '&::placeholder': {
                                    color: theme.colors['custom-gray'][8],
                                },
                                borderColor: theme.colors['custom-gray'][7],
                                '&:focus': {
                                borderColor: theme.colors['bright-green'][0],
                                },
                                color: '#484948',
                            },
                            label: {
                                color: "#484948",
                            },
                            description: {
                                color: "#8C9196"
                            },
                            icon: {
                                color: "#d5d9de",
                            },
                            required:{
                                color: "#004F65"
                            }
                        })}
                    />

                    <TextInput
                        size="md"
                        label="Phone"
                        placeholder="+31 528 123456"
                        required
                        icon={<Phone size={16} />}
                        {...form.getInputProps('phone')}
                        styles={(theme) => ({
                            input: {
                                '&::placeholder': {
                                    color: theme.colors['custom-gray'][8],
                                },
                                borderColor: theme.colors['custom-gray'][7],
                                '&:focus': {
                                    borderColor: theme.colors['bright-green'][0],
                                },
                                color: '#484948',
                            },
                            label: {
                                color: "#484948",
                            },
                            icon: {
                                color: "#d5d9de",
                            },
                            required:{
                                color: "#004F65"
                            }
                        })}
                    />

                    <Button color="bright-green" type="submit" size="lg" disabled={loading}>Save changes</Button>
                </Stack>
            </div>
        </form>
    );
}