import {Drawer, Group, Stack, Text, Title} from "@mantine/core";
import NavbarButton from "./_navbarButton";
import {useContext, useEffect} from "react";
import {UserContext} from "../../providers/user";
import {FooterContext} from "../../providers/footer";
import {NavbarContext} from "../../providers/navbar";
import LogoutButton from "../buttons/LogoutButton";

const Settings = () => <svg
xmlns="http://www.w3.org/2000/svg"
width="34"
height="34"
viewBox="0 0 34 34"
>
<path fill="none" d="M0 0h34v34H0z" data-name="Path 273"></path>
<path
  fill="none"
  stroke="#d0d4d9"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth="2"
  d="M14.394 5.049a2.681 2.681 0 015.211 0 2.682 2.682 0 004 1.658 2.683 2.683 0 013.687 3.687 2.682 2.682 0 001.657 4 2.681 2.681 0 010 5.211 2.682 2.682 0 00-1.658 4 2.683 2.683 0 01-3.687 3.687 2.682 2.682 0 00-4 1.657 2.681 2.681 0 01-5.211 0 2.682 2.682 0 00-4-1.658 2.683 2.683 0 01-3.687-3.687 2.682 2.682 0 00-1.657-4 2.681 2.681 0 010-5.211 2.682 2.682 0 001.658-4 2.683 2.683 0 013.687-3.687 2.679 2.679 0 004-1.657z"
  data-name="Path 274"
></path>
<path
  fill="none"
  stroke="#d0d4d9"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth="2"
  d="M14 17a3 3 0 103-3 3 3 0 00-3 3"
  data-name="Path 275"
></path>
</svg>;

const User = () => <svg
xmlns="http://www.w3.org/2000/svg"
width="34"
height="34"
viewBox="0 0 34 34"
>
<path fill="none" d="M0 0h34v34H0z" data-name="Path 241"></path>
<path
  fill="none"
  stroke="#d0d4d9"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth="2"
  d="M11.333 9.917A5.667 5.667 0 1017 4.25a5.667 5.667 0 00-5.667 5.667"
  data-name="Path 242"
></path>
<path
  fill="none"
  stroke="#d0d4d9"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth="2"
  d="M8.5 29.75v-2.833a5.667 5.667 0 015.667-5.667h5.667a5.667 5.667 0 015.666 5.667v2.833"
  data-name="Path 243"
></path>
</svg>;

const Phone = () => <svg
xmlns="http://www.w3.org/2000/svg"
width="34"
height="34"
viewBox="0 0 34 34"
>
<path fill="none" d="M0 0h34v34H0z" data-name="Path 276"></path>
<path
  fill="none"
  stroke="#d0d4d9"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth="2"
  d="M6.176 4h6.353l3.176 7.941-3.971 2.382a17.471 17.471 0 007.941 7.941l2.382-3.971L30 21.471v6.353A3.176 3.176 0 0126.824 31 25.412 25.412 0 013 7.176 3.176 3.176 0 016.176 4"
  data-name="Path 277"
></path>
</svg>;

const Bell = () => <svg
xmlns="http://www.w3.org/2000/svg"
width="34"
height="34"
viewBox="0 0 34 34"
>
<path fill="none" d="M0 0h34v34H0z" data-name="Path 270"></path>
<path
  fill="none"
  stroke="#d0d4d9"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth="2"
  d="M14.148 6.996a2.852 2.852 0 015.7 0 9.788 9.788 0 015.7 8.391v4.2a5.592 5.592 0 002.851 4.2H5.594a5.592 5.592 0 002.851-4.2v-4.2a9.788 9.788 0 015.7-8.391"
  data-name="Path 271"
></path>
<path
  fill="none"
  stroke="#d0d4d9"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth="2"
  d="M12.804 24.21v1.4a4.2 4.2 0 008.391 0v-1.4"
  data-name="Path 272"
></path>
</svg>;

export default function AppNavbar() {
    const {data} = useContext(UserContext);
    const {setShown: setFooterShown} = useContext(FooterContext);
    const {shown: navbarShown, setShown: setNavbarShown} = useContext(NavbarContext);

    const navbarOptions = [
        {
            text: "CUSTOMER DETAILS",
            actionLink: "/me",
            Icon: User,
        },
        {
            text: "NOTIFICATIONS",
            actionLink: "/notifications",
            Icon: Bell,
        },
        {
            text: "SETTINGS",
            actionLink: "/settings",
            Icon: Settings,
        },
        {
            text: "CONTACT",
            actionLink: "/contact",
            Icon: Phone,
        },
    ];

    /**
     * Hide of show the footer based on the state of the navbar.
     */
    useEffect(() => {
        if (!navbarShown) {
            setFooterShown(true);
            return;
        }

        setFooterShown(false);
    }, [navbarShown])

    return (
        <Drawer
            position="left"
            size="full"
            opened={navbarShown}
            onClose={() => setNavbarShown(false)} // Footer will be set to shown via the useEffect.
            zIndex={2}
            withCloseButton={false}
        >
            <Stack justify={"space-between"} style={{height: "100%"}}>
                <Stack>
                    <Stack style={{backgroundColor: "#69B365"}}>
                        <Stack m="20px 0 20px 60px" spacing={0}>
                            <Title order={3} style={{color: "white"}}>Hi there, {data?.name}!</Title>
                            <Text size="sm" style={{color: "white"}}>You are signed in with {data?.email}.</Text>
                        </Stack>
                    </Stack>

                    <Stack align={"flex-start"} ml={"md"}>
                        {navbarOptions.map((value) =>
                            <NavbarButton key={value.actionLink} text={value.text} actionLink={value.actionLink} Icon={value.Icon}/>)}
                    </Stack>
                </Stack>

                <Group position="center" grow mx={"md"} mb={"md"} style={{alignContent: "flex-end"}}>
                    <LogoutButton />
                </Group>
            </Stack>
        </Drawer>
    );
}