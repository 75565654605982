import {Location} from "../../../types";
import {Marker} from "react-map-gl";

type BunkerLocationMarkerProps = {
    bunkerLocation: Location,
    onClick: (event: Location) => void,
}

export default function BunkerLocationMarker(props: BunkerLocationMarkerProps) {
    const {bunkerLocation, onClick} = props;

    return (
        <Marker longitude={bunkerLocation.lon} latitude={bunkerLocation.lat} onClick={() => onClick(bunkerLocation)}>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
            >
            <g data-name="Group 72" transform="translate(-942 -102)">
                <g data-name="Group 68" transform="translate(718 -94)">
                <circle
                    cx="19"
                    cy="19"
                    r="19"
                    fill="#fff"
                    data-name="Ellipse 272"
                    transform="translate(224 196)"
                ></circle>
                </g>
                <g data-name="Group 69" transform="translate(956 115.692)">
                <circle
                    cx="4.5"
                    cy="4.5"
                    r="4.5"
                    fill="#69b365"
                    data-name="Ellipse 272"
                    transform="translate(0 .308)"
                ></circle>
                </g>
                <g data-name="Group 70" transform="translate(956 104.692)">
                <circle
                    cx="4.5"
                    cy="4.5"
                    r="4.5"
                    fill="#289323"
                    data-name="Ellipse 272"
                    transform="translate(0 .308)"
                ></circle>
                </g>
                <g data-name="Group 71" transform="translate(956 126.582)">
                <circle
                    cx="4.5"
                    cy="4.5"
                    r="4.5"
                    fill="#a9d4a7"
                    data-name="Ellipse 272"
                    transform="translate(0 .418)"
                ></circle>
                </g>
            </g>
            </svg>
        </Marker>
    );
}