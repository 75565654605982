import {Footer, Group} from "@mantine/core";
import NavigationButton from "./_navigationButton";
import {GasStation, Home, User} from "tabler-icons-react";
import {useContext} from "react";
import {FooterContext} from "../../providers/footer";

export default function AppFooter() {
    const {shown} = useContext(FooterContext);

    return (
        <Footer
            zIndex={500}
            height={63}
            styles={(themes) => ({
                root: {
                    backgroundColor: themes.colors.blue[0],
                }
            })}
            hidden={!shown}
        >
            <Group position="center" spacing={100} style={{height: "100%", backgroundColor: "#FCFCFC"}}>
                <NavigationButton Icon={Home} navigationUrl={'/'} />
                <NavigationButton Icon={GasStation} navigationUrl={'/requests'} />
                <NavigationButton Icon={User} navigationUrl={'/me'} />
            </Group>
        </Footer>
    );
}