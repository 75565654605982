import React, { useEffect, useRef, useState } from "react";
import { fetchShip, fetchShips } from "../../api/ships";
import { Ship } from "../../../types";
import { DefaultProps } from "@mantine/styles";
import { DEBOUNCE_TIMEOUT } from "../../constants";
import { Autocomplete, AutocompleteItem, AutocompleteStylesNames, Loader } from "@mantine/core";

interface ShipSelectProps extends DefaultProps<AutocompleteStylesNames> {
  error?: React.ReactNode;
  defaultValue?: string;
  onItemSubmit: (value: AutocompleteItem) => void;
  onClear: () => void;
  required?: boolean;
  placeholder?: string;
  label?: string;
}

export function ShipSelect(props: ShipSelectProps) {
  const { onClear, defaultValue = "", placeholder, label, onItemSubmit, ...restProps } = props;
  const timeoutRef = useRef<number>(-1);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AutocompleteItem[]>([]);

  useEffect(() => {
    defaultValue && setShip(defaultValue);
  }, []);

  const setShip = async (id: string) => {
    setLoading(true);
    let data = await fetchShip(defaultValue);
    setLoading(false);

    let currentShip = {
      ship: data,
      value: `${data.name} (${data.imo_number})`,
    };

    setValue(currentShip.ship.name);
    setData([currentShip]);
    onItemSubmit(currentShip);
  };

  const onChange = (val: string) => {
    // Reset the wait period before sending the request to prevent spamming the API.
    window.clearTimeout(timeoutRef.current);

    setValue(val);
    setData([]);
    onClear();

    if (val.trim().length === 0) {
      setLoading(false);
    } else {
      setLoading(true);

      // Send the request after waiting the global DEBOUNCE_TIMEOUT to ensure that the typing has stopped.
      timeoutRef.current = window.setTimeout(async () => {
        let filteredData = await fetchShips(0, 100, val);
        setLoading(false);

        setData(
          filteredData.data.map((c: Ship) => {
            return {
              ship: c,
              value: `${c.name} (${c.imo_number})`,
            };
          })
        );
      }, DEBOUNCE_TIMEOUT);
    }
  };

  return (
    <Autocomplete
      {...restProps}
      onItemSubmit={onItemSubmit}
      data={data}
      value={value}
      limit={20}
      onChange={onChange}
      rightSection={loading ? <Loader size={16} color="bright-green" /> : null}
      placeholder={placeholder ?? "Select a ship"}
      nothingFound={"No ships found"}
      label={label ?? "Ship"}
      styles={(theme) => ({
          rightSection: { pointerEvents: "none" },
          input: {
              '&::placeholder': {
                  color: theme.colors['custom-gray'][8],
              },
              borderColor: theme.colors['custom-gray'][7],
              '&:focus': {
              borderColor: theme.colors['bright-green'][0],
              },
              color: '#484948',
          },
          label: {
              color: "#484948",
          },
          required:{
              color: "#004F65"
          },
          item: {
              color: "#484948",
              '&:hover': {
                  backgroundColor: "#a9d4a7",
                  borderColor: "#a9d4a7",
              },
          },
      })}
    />
  );
}
