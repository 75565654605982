import {
  Accordion,
  Anchor,
  Center,
  createStyles,
  Divider,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { PatchableBunkerRequest } from "../../types";
import dayjs from "dayjs";
import useShipData from "../hooks/ship/useShipData";
import { Link } from "react-router-dom";
import useTimeSlotData from "../hooks/timeSlot/useTimeSlotData";

type CreateBunkerRequestFormProps = {
  bunkerRequest: PatchableBunkerRequest;
  onCancel?: () => void;
};

const useStyles = createStyles((theme) => ({
  accordion: {
    padding: 0,
  },

  accordionControl: {
    backgroundColor: "whitesmoke",
  },
}));

export default function BunkerRequestConfirmation(props: CreateBunkerRequestFormProps) {
  const { bunkerRequest, onCancel } = props;
  const { classes } = useStyles();
  const {
    data: ship,
    status: shipStatus,
    isFetching: shipFetching,
  } = useShipData(bunkerRequest.ship_id);
  const {
    data: timeSlot,
    status: timeSlotStatus,
    isFetching: timeSlotFetching,
  } = useTimeSlotData(bunkerRequest.time_slot_id);

  const isShipLoading = shipStatus === "loading" || shipFetching;
  const isTimeSlotLoading = timeSlotStatus === "loading" || timeSlotFetching;

  return (
    <>
      <Stack
        spacing={0}
        p={"sm"}
        sx={(theme) => ({
          backgroundColor: "#C7E2C6",
        })}
      >
        <Title mb={0} order={4}>
          Please check your information
        </Title>
      </Stack>

      <Accordion
        classNames={{
          content: classes.accordion,
          control: classes.accordionControl,
        }}
        styles={{
          control: {
              color: "#484948"
          },
          panel: {
              color: "#484948"
          },
          chevron: {
            color: '#289323',
            transform: 'scale(1.3)',
            '&[data-rotate]': {
              transform: 'scale(1.3) rotate(180deg)',
            }
          }
      }}
        defaultValue="request-details"
       
      >
        <Accordion.Item value="request-details">
          <Accordion.Control>
            <Text size={"sm"} weight={700}>Bunker request details</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Group p={"md"} position={"apart"}>
              <Text>Date</Text>
              <Text size={"sm"}>{dayjs(bunkerRequest.date).format("DD-MM-YYYY")}</Text>
            </Group>

            <Divider />

            <Group p={"md"} position={"apart"}>
              <Text>Time slot</Text>
              {isTimeSlotLoading ? (
                <Loader size={"sm"} color="bright-green" />
              ) : (
                <Text size={"sm"}>
                  {timeSlot.start_time} - {timeSlot.end_time}
                </Text>
              )}
            </Group>

            <Divider />

            <Group p={"md"} position={"apart"}>
              <Text>Expected amount</Text>
              <Text size={"sm"}>{bunkerRequest.expected_amount} kg</Text>
            </Group>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="ship-details">
          <Accordion.Control>
            <Text size={"sm"} weight={700}>Ship details</Text>
          </Accordion.Control>
          <Accordion.Panel>
            {isShipLoading ? (
              <Center>
                <Loader color="bright-green" />
              </Center>
            ) : (
              <>
                <Group p={"md"} position={"apart"}>
                  <Text>Name</Text>
                  <Text size={"sm"}>{ship.name}</Text>
                </Group>

                <Divider />

                <Group p={"md"} position={"apart"}>
                  <Text>Identification number</Text>
                  <Text size={"sm"}>{ship.imo_number}</Text>
                </Group>

                <Divider />

                <Group p={"md"} position={"apart"}>
                  <Text>Phone number</Text>
                  <Text size={"sm"}>
                    <Anchor component={Link} to={`tel:${ship.phone}`}>
                      {ship.phone}
                    </Anchor>
                  </Text>
                </Group>
              </>
            )}
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="customer-details">
          <Accordion.Control>
            <Text size={"sm"} weight={700}>Customer details</Text>
          </Accordion.Control>
          <Accordion.Panel>
            {isShipLoading ? (
              <Center>
                <Loader color="bright-green" />
              </Center>
            ) : (
              <>
                <Group p={"md"} position={"apart"}>
                  <Text>Full name</Text>
                  <Text size={"sm"}>{ship.customer?.name}</Text>
                </Group>

                <Divider />

                <Group p={"md"} position={"apart"}>
                  <Text>E-mail address</Text>

                  <Text size={"sm"}>
                    {ship.customer?.email_operations ? (
                      <Anchor component={Link} to={`mailto:${ship.customer?.email_operations}`}>
                        {ship.customer?.email_operations}
                      </Anchor>
                    ) : (
                      ""
                    )}
                  </Text>
                </Group>

                <Divider />

                <Group p={"md"} position={"apart"}>
                  <Text>Phone number</Text>
                  <Text size={"sm"}>
                    {ship.customer?.phone ? (
                      <Anchor component={Link} to={`tel:${ship.customer.phone}`}>
                        {ship.customer.phone}
                      </Anchor>
                    ) : (
                      ""
                    )}
                  </Text>
                </Group>
              </>
            )}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
