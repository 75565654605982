import {Checkbox, MultiSelect, Stack, Button, Text} from "@mantine/core";
import {NOTIFICATION_SETTING_EVENTS} from "../../constants";
import {useForm} from "@mantine/form";
import {ProfileSettings, User} from "../../../types";

type NotificationSettingsFormProps = {
    user: User,
    onSubmit: (profileSettings: ProfileSettings) => void,
    loading: boolean,
}

export default function NotificationSettingsForm(props: NotificationSettingsFormProps) {
    const {user, onSubmit, loading} = props;

    const form = useForm<ProfileSettings>({
        initialValues: {
            notification_settings: user?.notification_settings ??  [],
            sms_notifications: user?.sms_notifications ?? false,
            email_notifications: user?.email_notifications ?? false,
        },
    });

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack spacing="sm">
                <MultiSelect
                    data={NOTIFICATION_SETTING_EVENTS}
                    label="Events that you will be notified upon"
                    placeholder="Notification event"
                    {...form.getInputProps('notification_settings')}
                    styles={(theme) => ({
                        label: {
                            color: "#484948",
                            fontWeight: 700,
                            marginBottom: 8,
                        },
                        value: {
                            color: "#484948",
                            '&:hover': {
                                backgroundColor: "#DBDBDB",
                                borderColor: "#DBDBDB",
                            },
                            '& button': {
                                color: "#484948",
                            }
                        },
                        rightSection: {
                            '& svg': {
                                color: "#69B365 !important",
                            }
                        },
                        item: {
                            color: "#484948",
                            '&:hover': {
                                backgroundColor: "#a9d4a7",
                                borderColor: "#a9d4a7",
                            },
                        },
                    })}
                />

                <Text weight={700} size={14}>Channels where you will be notified on</Text>
                <Checkbox
                    value="sms_notifications"
                    label="Receive notifications via SMS"
                    {...form.getInputProps('sms_notifications', {type: 'checkbox'})}
                    styles={(theme) => ({
                        label: {
                            color: "#484948",
                        },
                        input: {
                            '&:checked': {
                                backgroundColor: "#69B365",
                                borderColor: "#69B365",
                            }
                        }
                    })}
                />
                <Checkbox
                    value="email_notifications"
                    label="Receive notifications via e-mail"
                    {...form.getInputProps('email_notifications', {type: 'checkbox'})}
                    styles={(theme) => ({
                        label: {
                            color: "#484948",
                        },
                        input: {
                            '&:checked': {
                                backgroundColor: "#69B365",
                                borderColor: "#69B365",
                            }
                        }
                    })}
                />

                <Button type="submit" size="lg" loading={loading}>Save</Button>
            </Stack>
        </form>
    );
}