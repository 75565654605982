import { Button, Group, Select, Stack, Text, Textarea } from "@mantine/core";
import { useState } from "react";

type CancelBunkerRequestFormProps = {
  onClose: () => void;
  onSubmit: (reason: string) => void;
};

function CancelBunkerRequestForm(props: CancelBunkerRequestFormProps) {
  const { onClose, onSubmit } = props;
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const [reason, setReason] = useState<string>("");

  const defaultOptions = [
    "Technical issues with the barges",
    "Client requested a change in planning",
    "Delay of the ship",
    "Other",
  ];

  const onSelectChange = (value: string | null) => {
    setSelectValue(value);
    value != "Other" && setReason(value ?? "");
  };

  return (
    <>
      <Text size="sm">
        You are about to cancel a bunker request. The customer will be informed of this. Are you
        sure?
      </Text>
      <Select
        label="Reason"
        placeholder="Cancellation reason"
        mb="md"
        data={defaultOptions.map((item) => ({ value: item, label: item }))}
        value={selectValue}
        onChange={onSelectChange}
      />
      {selectValue == "Other" && (
        <Textarea
          mb="md"
          placeholder="The reason the customer wants to cancel the bunker request"
          value={reason}
          onChange={(evt: any) => setReason(evt.currentTarget.value)}
          autoFocus
        />
      )}
      <Stack>
        <Button onClick={() => onClose()}>Go back</Button>
        <Button onClick={() => onSubmit(reason)} color="custom-red">
          Cancel bunker request
        </Button>
      </Stack>
    </>
  );
}

export default CancelBunkerRequestForm;
