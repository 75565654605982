import {Anchor, Group, LoadingOverlay, Stack, Text, Title} from "@mantine/core";
import LoginForm from "../components/forms/LoginForm";
import useLogin from "../hooks/useLogin";
import {useNavigate} from "react-router-dom";
import BrandHeader from "../components/headers/BrandHeader";
import useContactPropertyData from "../hooks/settings/useContactPropertyData";

export default function Login() {
    const {login, loading} = useLogin();
    const navigate = useNavigate();
    const {data: contactData, isLoading: isLoadingContactData} = useContactPropertyData();
    const contactEmail = !isLoadingContactData && contactData.find((i: any) => i.name === "main_mail")?.value;

    return (
        <>
            <Group position={"center"}>
                <BrandHeader />
            </Group>

            <Stack
                align="center"
                justify="center"
                style={{height: "75vh"}}
                spacing={25}
            >

                <Stack align={"center"} spacing={0}>
                    <Title order={2}>Login</Title>
                    <Text>Access your account.</Text>
                </Stack>

                <Stack spacing={0} align={"stretch"} style={{width: "90%", maxWidth: "350px"}}>
                    <div style={{position: "relative"}}>
                        <LoadingOverlay visible={loading}/>
                        <LoginForm onSubmit={(values) => login(values)}/>

                        <Group position="apart" mt={"md"}>
                            {!isLoadingContactData && <Anchor color="bright-green" href={`mailto:${contactEmail}`} size="sm">Request an account</Anchor>}
                            <Anchor color="bright-green" size="sm" onClick={() => navigate('/password-forgotten')}>Forgot your password?</Anchor>
                        </Group>
                    </div>
                </Stack>

            </Stack>
        </>
    );
}