import {Anchor, Group, Stack, Text, Title} from "@mantine/core";
import {Mail} from "tabler-icons-react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {validateEmail} from "../validator";
import BrandHeader from "../components/headers/BrandHeader";

export default function PasswordResetConfirmation() {
    const navigate = useNavigate();
    const {email} = useParams();

    /**
     * Navigate away if the e-mail parameter is incorrect.
     */
    useEffect(() => {
        if (validateEmail(email)) {
            navigate('/');
        }
    }, [email]);

    return (
        <>
            <Group position="center">
                <BrandHeader showBackButton={true} backButtonUrl={'/login'} />
            </Group>

            <Stack
                justify="center"
                spacing={0}
                style={{height: "75vh"}}
            >
                <Stack align="center" spacing={0} mb={50}>
                    <Stack align="center" m={10} spacing={0}>
                        <Title order={2}>E-mail Sent</Title>
                        <Text align="center" style={{maxWidth: "500px"}}>
                            An e-mail has been sent to your provided e-mail address with further instructions if the provided address is valid.
                        </Text>
                    </Stack>

                    <Group position="center" m={"md"} spacing={20}>
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 15.637 15.637"
                      >
                        <path fill="none" d="M0 0h15.637v15.637H0z" data-name="Path 280"></path>
                        <path
                          fill="none"
                          stroke="#8C9196"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M1.38 3.877A1.509 1.509 0 012.811 2.3h10.016a1.509 1.509 0 011.431 1.577v7.884a1.509 1.509 0 01-1.431 1.577H2.811a1.509 1.509 0 01-1.431-1.577z"
                          data-name="Path 281"
                        ></path>
                        <path
                          fill="none"
                          stroke="#8C9196"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M1.38 5.008l6.439 2.76 6.439-2.76"
                          data-name="Path 282"
                        ></path>
                      </svg>
                        <Text align="center">{email}</Text>
                    </Group>
                </Stack>

                <Group position="center">
                    <Anchor onClick={() => navigate('/login')}>Return to login page</Anchor>
                </Group>
            </Stack>
        </>
    );
}