import {useEffect, useState} from "react";
import {hasAuthToken as hasAuthTokenFn} from "../tools";
import {Maybe, User} from "../../types";
import {API_URL, FETCH_HEADERS} from "../constants";

export default function useUser() {
    const [isAuthed, setIsAuthed] = useState<boolean>(false);
    const [data, setData] = useState<Maybe<User>>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [initialFetch, setInitialFetch] = useState<boolean>(true);
    const hasAuthToken = hasAuthTokenFn();

    /**
     * Execute fetch on mount.
     */
    useEffect(() => {
        if(hasAuthToken) {
            fetchData()
        } else {
            setLoading(false);
            setInitialFetch(false);
        }
    }, [hasAuthToken]);

    /**
     * Fetch the user's profile.
     */
    const fetchData = async function() {
        setLoading(true);

        const response = await fetch(`${API_URL}/auth/profile`, {
            method: "POST",
            ...FETCH_HEADERS()
        }).then((res) => res.json())

        if (response.data) {
            setIsAuthed(true);
        }

        setData(response.data?.user);
        setLoading(false);
        setInitialFetch(false);
    }

    /**
     * Refetch the profile of the user.
     */
    const refetch = async () => await fetchData();

    return {
        isAuthed,
        data,
        loading,
        initialFetch,
        refetch,
    };
}