import {Container, Button, createStyles, Group, Stack, Text, Title} from '@mantine/core'
import React from "react";
import {Notification} from "../../types";
import FancyList from "../components/FancyList";
import {fetchNotifications} from "../api/notifications";
import {Eye, Trash} from "tabler-icons-react";
import {Link} from "react-router-dom";
import {humanizeTime} from "../tools";
import DeleteNotificationButton from "../components/buttons/DeleteNotificationButton";

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 60,
        paddingBottom: 20,
        height: "100%"
    },
}));

export default function Notifications() {
    const {classes} = useStyles()

    const labelRenderer = (data: Notification) => {
        return (
            <Group position={"apart"} spacing={0}>
                <Stack spacing={0}>
                    <Text>{data.data.title}</Text>
                    <Text size="sm" color="dimmed" weight={400}>{data.data.description}</Text>
                    <Text size="sm" color="dimmed" weight={400} mt={5}>{humanizeTime(data.created_at)}</Text>
                </Stack>
                <Group mt={5}>
                    {data.data.bunker_request_id && (
                        <Button component={Link} to={`/requests`}>
                            <Eye />
                        </Button>
                    )}
                    <DeleteNotificationButton id={data.id}/>
                </Group>
            </Group>
        )
    }

    return (
        <Container className={classes.root}>
            <Title mb={"lg"} mt="18px">Notifications</Title>

            <FancyList<Notification>
                queryKey={'notifications'}
                queryFn={fetchNotifications}
                labelRenderer={labelRenderer}
            />

        </Container>
    );
}