import { SortingRule } from "../../types";
import {API_URL, FETCH_HEADERS} from "../constants";
import { generateSortString } from "../tools";

export const fetchLocations = async (page: number, pageSize: number, query: string = "", sortBy: SortingRule[] = []) => {
    return await fetch(`${API_URL}/location?page=${page + 1}&limit=${pageSize}&query=${query}${generateSortString(sortBy)}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchLocation = async (id?: string) => {
    return await fetch(`${API_URL}/location/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};
