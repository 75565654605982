import {createContext, ReactNode, useState} from 'react';
import {useDebouncedValue} from "@mantine/hooks";
import {DEBOUNCE_TIMEOUT} from "../constants";

type Props = {
    children?: ReactNode,
}

type SearchContextProps = {
    query: string,
    debouncedQuery: string,
    setQuery: (searchQuery: string) => void,
}

export const SearchContext = createContext<SearchContextProps>({
    query: "",
    debouncedQuery: "",
    setQuery: () => {
    },
});

export default function SearchProvider(props: Props) {
    const [query, setQueryState] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, DEBOUNCE_TIMEOUT);

    function setQuery(query: string) {
        setQueryState(query);
    }

    return (
        <SearchContext.Provider value={{query, debouncedQuery, setQuery}}>
            {props.children}
        </SearchContext.Provider>
    );
}