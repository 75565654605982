import {API_URL, FETCH_HEADERS} from "../constants";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {showNotification} from "@mantine/notifications";
import {formatErrors, isEmptyArray} from "../tools";
import {PasswordForgotten} from "../../types";

export default function usePasswordForgotten() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const fetchData = async function(values: PasswordForgotten) {
        return await fetch(`${API_URL}/auth/password-forgotten`, {
            method: "POST",
            body: JSON.stringify({email: values.email, email_confirmation: values.email}),
            ...FETCH_HEADERS(),
        }).then((d) => d.json());
    }

    const sendResetPasswordRequest = async function(values: PasswordForgotten) {
        setLoading(true);
        const response = await fetchData(values);
        const errors = formatErrors(response);

        response?.data
            ? navigate(`/password-reset-confirmed/${values.email}`)
            : showNotification({
                title: `Something went wrong`,
                message: isEmptyArray(errors) ? 'Your password could not be reset.' : errors[0],
                color: 'red'
            });

        setLoading(false);
    }

    return {
        sendResetPasswordRequest,
        loading,
    }
}