import React from "react";
import {Button, Loader} from "@mantine/core";
import {Trash} from "tabler-icons-react";
import useMutateNotificationData from "../../hooks/notification/useMutateNotificationData";
import {deleteNotification} from "../../api/notifications";

type DeleteNotificationButtonProps = {
    id: string;
}

export default function DeleteNotificationButton(props: DeleteNotificationButtonProps) {
    const {id} = props;
    const { mutate, isLoading } = useMutateNotificationData(deleteNotification);

    return (
        <Button
            color={"custom-red"}
            disabled={isLoading}
            onClick={() => mutate(id)}>
            {!isLoading ? <Trash /> : <Loader size={"sm"} color={"white"} />}
        </Button>
    )
}
