import { API_URL, FETCH_HEADERS } from "../constants";

export const fetchTimeSlotExceptions = async (locationId: string) => {
  return await fetch(`${API_URL}/time-slot-exception?location_id=${locationId}`, {
    ...FETCH_HEADERS(),
  })
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
};
