import {useQuery} from 'react-query'
import {fetchShip} from "../../api/ships";

export default function useShipData(id?: string, onSuccess?: any, onError?: any) {
    return useQuery(['ships'], () => fetchShip(id),
        {
            onSuccess,
            onError,
            keepPreviousData: false,
        }
    );
}
