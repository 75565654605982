import {Group, LoadingOverlay, Stack, Title} from '@mantine/core';
import useEmailVerify from "../hooks/useEmailVerify";
import VerifyEmailForm from "../components/forms/VerifyEmailForm";
import React from "react";
import BrandHeader from "../components/headers/BrandHeader";

function VerifyEmail() {
    const { loading, setFormData, onSubmit } = useEmailVerify();

    return (
        <>
            <Group position="center">
                <BrandHeader showBackButton={true} backButtonUrl={'/login'} />
            </Group>

            <Stack
                justify="center"
                align="center"
                style={{height: "75vh"}}
            >
                <Stack align="center" spacing={0}>
                    <Title order={2}>Set up account</Title>
                </Stack>

                <Group position="center" grow style={{width: "90%", maxWidth: "350px"}}>
                    <div style={{position: "relative"}}>
                        <LoadingOverlay visible={loading} />
                        <VerifyEmailForm submitText={"Set password"} onSubmit={onSubmit} setValues={setFormData}/>
                    </div>
                </Group>
            </Stack>
        </>
    );
}

export default VerifyEmail;