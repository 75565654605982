import {Group, Card, Stack, Text, Anchor} from "@mantine/core";

type ContactCardProps = {
    contactAddress: string;
    description: string;
    type: string;
}

export default function ContactCard(props: ContactCardProps) {
    const {contactAddress, description, type} = props;

    return (
        <>
            <Card style={{backgroundColor: "#F0F0F0"}}>
                <Group noWrap>
                    {type === 'phone' ? <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        style={{marginRight: 10}}
                        >
                        <path fill="none" d="M0 0h34v34H0z" data-name="Path 276"></path>
                        <path
                            fill="none"
                            stroke="#69b365"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6.176 4h6.353l3.176 7.941-3.971 2.382a17.471 17.471 0 007.941 7.941l2.382-3.971L30 21.471v6.353A3.176 3.176 0 0126.824 31 25.412 25.412 0 013 7.176 3.176 3.176 0 016.176 4"
                            data-name="Path 277"
                        ></path>
                        </svg> 
                        : 
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        style={{marginRight: 10}}
                        >
                        <path fill="none" d="M0 0h34v34H0z" data-name="Path 280"></path>
                        <path
                            fill="none"
                            stroke="#69b365"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 8.429A3.281 3.281 0 016.111 5h21.778A3.281 3.281 0 0131 8.429v17.142A3.281 3.281 0 0127.889 29H6.111A3.281 3.281 0 013 25.571z"
                            data-name="Path 281"
                        ></path>
                        <path
                            fill="none"
                            stroke="#69b365"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 10.889l14 6 14-6"
                            data-name="Path 282"
                        ></path>
                        </svg>
                    }

                    <Stack spacing={0}>
                        <Anchor size="lg" weight={"700"} color={"custom-green"} href={`${type === 'phone' ? 'tel:' : 'mailTo:'}${contactAddress}`}>{contactAddress}</Anchor>
                        <Text size="sm" color={"#484948"}>{description}</Text>
                    </Stack>
                </Group>
            </Card>
        </>
    );
}
