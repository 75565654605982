import {ActionIcon} from "@mantine/core";
import {ArrowLeft} from "tabler-icons-react";
import Brand from "../AppShell/_brand";
import {useNavigate} from "react-router-dom";

type BrandHeaderProps = {
    showBackButton?: boolean,
    backButtonUrl?: string,
}

export default function BrandHeader(props: BrandHeaderProps) {
    const {showBackButton = false, backButtonUrl} = props;
    const navigate = useNavigate();

    return (
        <div style={{marginTop: "20px"}}>
            {showBackButton && (
                <div style={{position: "absolute", left: "10px"}}>
                    <ActionIcon
                        onClick={() => navigate(backButtonUrl ?? '/')}
                        variant="transparent"
                        size="lg"
                    >
                        <ArrowLeft size={40} />
                    </ActionIcon>
                </div>
            )}

            <Brand />
        </div>
    );
}