import {useContext, useState} from "react";
import {API_URL, FETCH_HEADERS} from "../constants";
import {isArray, isEmptyArray} from "../tools";
import {showNotification} from "@mantine/notifications";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../providers/user";
import {FooterContext} from "../providers/footer";
import {NavbarButtonContext} from "../providers/navbarButton";

export default function useOnboarding() {
    const [loading, setLoading] = useState(false);
    const {refetch} = useContext(UserContext);
    const {setShown: setFooterShown} = useContext(FooterContext);
    const {setShown: setNavbarButtonShown} = useContext(NavbarButtonContext);
    const navigate = useNavigate();

    const fetchData = async function() {
        return await fetch(`${API_URL}/user/has-boarded`, {
            method: "POST",
            body: JSON.stringify({has_boarded: true}),
            ...FETCH_HEADERS(),
        })
            .then((res) => res.json())
            .catch(() => {})
    }

    const boardingCompleted = async function() {
        setLoading(true);

        const response = await fetchData();

        setLoading(false);

        if (!response?.data) {
            showNotification({
                title: `Something went wrong`,
                message: (response?.errors && isArray(response?.errors) && !isEmptyArray(response?.errors)) ? response?.message : 'An unknown error occurred. Please try again later.',
                color: 'red'
            });

            return;
        }

        await refetch();

        navigate(`/`);
        setFooterShown(true);
        setNavbarButtonShown(true);
    }

    return {
        boardingCompleted,
        loading,
    }
}