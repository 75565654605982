import {ActionIcon, createStyles} from "@mantine/core";
import {Icon} from "tabler-icons-react";
import {useLocation, useNavigate} from "react-router-dom";

type NavigationButtonProps = {
    Icon: Icon,
    navigationUrl: string,
}

const styles = createStyles((themes) => ({
    nonActiveLink: {
        color: "#a9d4a7",
    },
    activeLink: {
        color: "#289323",
    }
}));

export default function NavigationButton(props: NavigationButtonProps) {
    const {Icon, navigationUrl} = props;
    const location = useLocation();
    const navigate = useNavigate();
    const {classes} = styles();

    return (
        <ActionIcon sx={{
            backgroundColor: location.pathname === navigationUrl ? '#C7E2C6' : 'transparent',
            width: 40,
            height: 40,
            borderRadius: '50%',
            '&:hover': {
                backgroundColor: '#C7E2C6',
            }
          }} onClick={() => navigate(navigationUrl)}  >
            <Icon size={24} className={location.pathname === navigationUrl ? classes.activeLink : classes.nonActiveLink} />
        </ActionIcon>
    );
}