import {API_URL} from "../constants";

export const fetchContactProperties = async () => {
    return await fetch(`${API_URL}/contact-property`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};