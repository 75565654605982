import {Group, LoadingOverlay, Stack, Text, Title} from "@mantine/core";
import BrandHeader from "../components/headers/BrandHeader";
import ResetPasswordForm from "../components/forms/ResetPasswordForm";
import useResetPassword from "../hooks/useResetPassword";

export default function ResetPassword() {
    const {resetPassword, loading} = useResetPassword();

    return (
        <>
            <Group position="center">
                <BrandHeader showBackButton={true} backButtonUrl={'/login'} />
            </Group>

            <Stack
                justify="center"
                align="center"
                style={{height: "75vh"}}
            >
                <Stack align="center" spacing={0}>
                    <Title order={2}>Password Reset</Title>
                    <Text align="center">Reset your password.</Text>
                </Stack>

                <Group position="center" grow style={{width: "90%", maxWidth: "350px"}}>
                    <div style={{position: "relative"}}>
                        <LoadingOverlay visible={loading} />
                        <ResetPasswordForm onSubmit={(values) => resetPassword(values)} />
                    </div>
                </Group>
            </Stack>
        </>
    );
}