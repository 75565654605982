import {useQuery} from 'react-query'
import {fetchLocation} from "../../api/locations";

export default function useLocationData(id?: string, onSuccess?: any, onError?: any) {
    return useQuery(['locations'], () => fetchLocation(id),
        {
            onSuccess,
            onError,
            keepPreviousData: false,
        }
    );
}
