import {useMutation} from 'react-query'
import {User} from "../../../types";
import {showNotification} from "@mantine/notifications";
import {Check} from "tabler-icons-react";
import {useContext} from "react";
import {UserContext} from "../../providers/user";

const onMutateSuccess = (values: User) => {
    showNotification({
        title: 'Profile updated',
        icon: <Check/>,
        message: `Your profile has been updated!`,
    })
}

const onMutateError = (error: string) => {
    showNotification({
        title: `Something went wrong`,
        message: `An error occurred while updating your profile. ${error}`,
        color: 'red'
    })
}

export default function useMutateUserData(mutationFn: (args: any) => Promise<unknown>, onSuccess: any = onMutateSuccess, onError: any = onMutateError) {
    const {refetch} = useContext(UserContext);

    return useMutation(mutationFn, {
        onSuccess: (data) => {
            refetch();
            onSuccess?.(data);
        },
        onError: (data) => onError?.(data)
    });
}
