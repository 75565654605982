import {API_URL, FETCH_HEADERS} from "../constants";
import {Ship} from "../../types";
import {handleRequestErrors} from "../tools";

export const fetchShips = async (page: number, pageSize: number, query: string = "") => {
    return await fetch(`${API_URL}/ship?page=${page + 1}&limit=${pageSize}&query=${query}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchShip = async (id?: string) => {
    return await fetch(`${API_URL}/ship/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function createShip(ship: Ship) {
    return await fetch(`${API_URL}/ship`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(ship)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function updateShip(ship: Ship) {
    return await fetch(`${API_URL}/ship/${ship.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(ship)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export async function deleteShip (ids: string[]) {
    return await fetch(`${API_URL}/ship`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
        body: JSON.stringify({
            "ships": ids
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}
