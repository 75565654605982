import {
  AutocompleteItem,
  AutocompleteStylesNames,
  Group,
  Input,
  LoadingOverlay,
  Paper,
  Text,
} from "@mantine/core";
import { DefaultProps } from "@mantine/styles";
import React, { useEffect, useState } from "react";
import { Plus } from "tabler-icons-react";
import { TimeSlot } from "../../../types";
import { fetchTimeSlots } from "../../api/timeSlots";

interface TimeSlotSelectProps extends DefaultProps<AutocompleteStylesNames> {
  error?: React.ReactNode;
  onChange: (value: string | null) => void;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  label?: string;
  date: Date;
  location: string;
  value?: string;
}

export function TimeSlotSelect(props: TimeSlotSelectProps) {
  const {
    onChange,
    value,
    disabled = false,
    label,
    date: dateProp,
    location,
    placeholder = "Select a time slot",
    ...restProps
  } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AutocompleteItem[]>([]);
  const [date, setDate] = useState<Date>(dateProp);

  useEffect(() => {
    !disabled && onLoad();
  }, []);

  useEffect(() => {
    !disabled && onReload();
  }, [date, location]);

  const onReload = () => {
    setLoading(true);
    onLoad();
  };

  // Only update if date is different
  useEffect(() => {
    if (dateProp.getTime() !== date.getTime()) setDate(dateProp);
  }, [dateProp]);

  const onLoad = async () => {
    let filteredData = await fetchTimeSlots(date, location);

    setData(
      filteredData.map((c: TimeSlot) => {
        return {
          value: c.id,
          label: `${c.start_time} - ${c.end_time}`,
        };
      })
    );

    setLoading(false);
  };

  const onValueChange = (arg: string) => {
    let newValue = value === arg ? "" : arg;
    onChange(newValue);
  };

  return (
    <>
      <Input.Wrapper 
              styles={(theme) => ({
                  label: {
                      color: "#484948",
                  },
                  required:{
                      color: "#004F65"
                  }
              })} label={label ?? "Time slot"} {...restProps}>
        {loading || disabled || data.length === 0 ? (
          <div style={{ position: "relative" }}>
            <LoadingOverlay
              loaderProps={{ size: "sm" }}
              visible={loading && !disabled}
              zIndex={0}
            />
            <Paper withBorder shadow="sm" p="md" style={{ minWidth: "100%" }}>
              <Group>
                <Text>{data.length === 0 ? "No time slots available" : placeholder}</Text>
              </Group>
            </Paper>
          </div>
        ) : (
          <>
            {data.map((timeSlot: any, i: number) => (
              <Paper
                mb={i + 1 < data.length ? "sm" : 0}
                withBorder
                shadow="sm"
                p="md"
                style={{
                  minWidth: "100%",
                  cursor: "pointer",
                  backgroundColor: timeSlot.value === value ? "#C7E2C6" : "",
                }}
                onClick={() => onValueChange(timeSlot.value)}
                key={timeSlot.label}
              >
                <Group position={"apart"}>
                  <Group>
                    <Plus size={16} color="#484948" />
                    <Text color="#484948">{timeSlot.label}</Text>
                  </Group>
                  <Text size={"xs"} color="#484948">{date.toLocaleDateString()}</Text>
                </Group>
              </Paper>
            ))}
          </>
        )}
      </Input.Wrapper>
    </>
  );
}
