import React, {useEffect} from "react";
import {useForm} from '@mantine/form'
import {Button, PasswordInput} from '@mantine/core';
import {validateSame, validateString} from "../../validator";

type VerifyEmailFormProps = {
    onSubmit: () => void;
    setValues: (values: any) => void;
    submitText?: string;
};

function VerifyEmailForm(props: VerifyEmailFormProps) {
    const {onSubmit, setValues, submitText} = props;

    const form = useForm({
        initialValues: {
            password: '',
            password_confirmation: '',
        },

        validate: (values) => ({
            password: validateString(values.password),
            password_confirmation: validateSame(values.password_confirmation, values.password, "the password field"),
        })
    });

    useEffect(() => {
        setValues(form.values)
    }, [form.values])

    return (
        <form onSubmit={form.onSubmit(() => onSubmit())}>
            <PasswordInput
                required
                label="Password"
                placeholder="Password"
                mb={"md"}
                styles={(theme) => ({
                    withIcon: {
                      borderColor: theme.colors['custom-gray'][7],
                        '&:has(input:focus)': {
                          borderColor: theme.colors['bright-green'][0],
                        },
                    },
                    input: {
                        '&::placeholder': {
                            color: theme.colors['custom-gray'][8],
                        }
                    },
                    visibilityToggle: {
                        color: theme.colors['custom-gray'][8],
                    },
                    innerInput: {
                        color: "#484948",
                    }
                  })}
                {...form.getInputProps('password')}
            />

            <PasswordInput
                required
                label="Password confirmation"
                placeholder="Password confirmation"
                mb={"md"}
                styles={(theme) => ({
                    withIcon: {
                      borderColor: theme.colors['custom-gray'][7],
                        '&:has(input:focus)': {
                          borderColor: theme.colors['bright-green'][0],
                        },
                    },
                    input: {
                        '&::placeholder': {
                            color: theme.colors['custom-gray'][8],
                        }
                    },
                    visibilityToggle: {
                        color: theme.colors['custom-gray'][8],
                    },
                    innerInput: {
                        color: "#484948",
                    }
                  })}
                {...form.getInputProps('password_confirmation')}
            />

            <Button fullWidth type={"submit"} mb={"md"}>{submitText ?? "Save"}</Button>
        </form>
    );
}

export default VerifyEmailForm;
