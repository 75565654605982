import BrandHeader from "../components/headers/BrandHeader";
import {Anchor, Container, Group, LoadingOverlay, Stack, Text} from "@mantine/core";
import ContactCard from "../components/cards/ContactCard";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchContactProperties} from "../api/contactProperties";
import {ContactProperty} from "../../types";
import {APP_NAME} from "../constants";

export default function Contact() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [contactData, setContactData] = useState<ContactProperty[]>([]);
    const [name, setName] = useState<ContactProperty>();
    const [location, setLocation] = useState<ContactProperty>();

    const onLoad = async function() {
        const data = await fetchContactProperties();

        if (!data) {
            return;
        }

        // TODO: refactor this
        setContactData(data.filter((item: ContactProperty) => item.type !== 'name' && item.type !== 'location'));
        setName(data.find((item: ContactProperty) => item.type === 'name'));
        setLocation(data.find((item: ContactProperty) => item.type === 'location'));
        setLoading(false);
    }

    useEffect(() => {
        onLoad();
    }, []);

    return loading ? (
        <div style={{position: "relative", width: "100%", height: "100%"}}>
            <LoadingOverlay visible={true} overlayOpacity={0} />
        </div>
    ) : (
        <>
            <Group position="center">
                <BrandHeader />
            </Group>

            <Container>
                <Stack spacing={0} ml={"10vw"} mt={"3.25vh"} mb={"3vh"}>
                    <Text color={"#484948"} weight={700} size="xl">{name?.value ?? APP_NAME}</Text>
                    <Text color={"#484948"} weight={700}>{location?.value ?? 'Den Haag, NL'}</Text>
                </Stack>

                <Stack>
                    {contactData.map((data: ContactProperty) =>
                        <ContactCard key={data.name} contactAddress={data.value} description={data.description} type={data.type} />
                    )}

                    <Group position="center" m={"md"}>
                        <Anchor color={"custom-red"} weight={"700"} onClick={() => navigate('/')}>Go to home</Anchor>
                    </Group>
                </Stack>
            </Container>
        </>
    );
}