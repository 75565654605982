import React, {createContext, ReactNode, useState} from "react";

type Props = {
    children?: ReactNode;
}

type NavbarProps = {
    shown: boolean,
    setShown: (value: boolean) => void,
}

export const NavbarContext = createContext<NavbarProps>({
    shown: false,
    setShown: () => {},
})

export default function NavbarProvider(props: Props) {
    const [shown, setShown] = useState(false);

    return (
        <NavbarContext.Provider value={{shown, setShown}}>
            {props.children}
        </NavbarContext.Provider>
    );
}