import { Button, Drawer, LoadingOverlay, Stack, Text } from "@mantine/core";
import BunkerLocationDetailCard from "../cards/BunkerLocationDetailCard";
import { useEffect, useState } from "react";
import { fetchBunkerLocation } from "../../api/bunkerLocations";
import { Location } from "../../../types";
import { useNavigate } from "react-router-dom";

type BunkerLocationDetailsDrawerProps = {
  bunkerLocationId: string;
  opened: boolean;
  onClose: () => void;
};

const PhoneIcon = () => <svg
xmlns="http://www.w3.org/2000/svg"
width="34"
height="34"
viewBox="0 0 34 34"
style={{marginLeft: 10}}
>
<path fill="none" d="M0 0h34v34H0z" data-name="Path 276"></path>
<path
    fill="none"
    stroke="#8C9196"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M6.176 4h6.353l3.176 7.941-3.971 2.382a17.471 17.471 0 007.941 7.941l2.382-3.971L30 21.471v6.353A3.176 3.176 0 0126.824 31 25.412 25.412 0 013 7.176 3.176 3.176 0 016.176 4"
    data-name="Path 277"
></path>
</svg>;

const MailIcon = () => <svg
xmlns="http://www.w3.org/2000/svg"
width="34"
height="34"
viewBox="0 0 34 34"
style={{marginLeft: 10}}
>
<path fill="none" d="M0 0h34v34H0z" data-name="Path 280"></path>
<path
    fill="none"
    stroke="#8C9196"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M3 8.429A3.281 3.281 0 016.111 5h21.778A3.281 3.281 0 0131 8.429v17.142A3.281 3.281 0 0127.889 29H6.111A3.281 3.281 0 013 25.571z"
    data-name="Path 281"
></path>
<path
    fill="none"
    stroke="#8C9196"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M3 10.889l14 6 14-6"
    data-name="Path 282"
></path>
</svg>;

export default function BunkerLocationDetailsDrawer(props: BunkerLocationDetailsDrawerProps) {
  const { bunkerLocationId, opened, onClose } = props;
  const [bunkerLocation, setBunkerLocation] = useState<Location>();
  const [fetching, setFetching] = useState(false);
  const navigate = useNavigate();

  const onButtonClick = function () {
    onClose();
    navigate(`/location/${bunkerLocation?.id}/request`);
  };

  const fetchLocation = async function () {
    setFetching(true);
    let data = await fetchBunkerLocation(bunkerLocationId);

    if (!data?.data) {
      return;
    }

    setBunkerLocation(data.data);
    setFetching(false);
  };

  /**
   * Fetch when the bunkerLocationId changes.
   */
  useEffect(() => {
    if (bunkerLocationId) {
      fetchLocation();
    }
  }, [bunkerLocationId]);

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="bottom"
      size="sm"
      withCloseButton={false}
      overlayColor={"white"}
      overlayBlur={1}
      overlayOpacity={0.5}
      styles={(theme) => ({
        drawer: {
          height: "unset",
          backgroundColor: "#C7E2C6",
        },
      })}
    >
      {!bunkerLocation || fetching ? (
        <div style={{ position: "relative", width: "100%", height: "250px" }}>
          <LoadingOverlay visible={true} overlayOpacity={0} />
        </div>
      ) : (
        <>
          <Stack style={{ position: "absolute", top: "-25%" }} spacing={0} ml={"md"}>
            <Text weight={700} size={"lg"} color="#1D1D1D">
              {bunkerLocation.name}
            </Text>
            <Text weight={400} color="#1D1D1D">{bunkerLocation.location}</Text>
          </Stack>

          <Stack align={"stretch"} m={"md"}>
            <BunkerLocationDetailCard
              Icon={PhoneIcon}
              title={bunkerLocation.phone}
              titleHref={"tel"}
              text={"Telephone"}
            />

            {bunkerLocation.phone2 ? (
              <BunkerLocationDetailCard
                Icon={PhoneIcon}
                title={bunkerLocation.phone2}
                titleHref={"tel"}
                text={"Alternative telephone"}
              />
            ) : null}

            <BunkerLocationDetailCard
              Icon={MailIcon}
              title={bunkerLocation.email}
              titleHref={"mailTo"}
              text={"E-mail"}
            />

            <Button size="lg" color="custom-red" onClick={onButtonClick}>
              Create bunker request
            </Button>
          </Stack>
        </>
      )}
    </Drawer>
  );
}
