import React, { ReactNode, useContext, useEffect, useReducer, useState } from "react";
import { useQuery } from "react-query";
import { Accordion, Button, Center, Group, Loader, Pagination, Text } from "@mantine/core";
import { Eye, Pencil, Trash } from "tabler-icons-react";
import { SearchContext } from "../providers/search";
import { SortingRule } from "../../types";

const PAGE_CHANGED = "PAGE_CHANGED";
const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";

// @ts-ignore
const reducer = (state: any, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

type FancyListProps<T> = {
  queryKey: string;
  queryFn: (
    queryPageIndex: number,
    queryPageSize: number,
    query?: string,
    querySortByState?: SortingRule[]
  ) => any;
  queryParameters?: any[];
  queryPageSize?: number;
  querySortBy?: SortingRule[];

  onEdit?: (id: T) => void;
  onDelete?: (id: T, onConfirm?: () => void) => void;
  onDetails?: (id: T) => void;
  labelRenderer?: (row: T) => ReactNode;
  detailsRenderer?: (row: T) => ReactNode;
};

function FancyList<T>(props: FancyListProps<T>) {
  const {
    queryKey,
    queryFn,
    queryPageSize: queryPageSizeProps,
    querySortBy = [],
    onEdit,
    onDelete,
    onDetails,
    labelRenderer,
    detailsRenderer,
  } = props;
  const { debouncedQuery } = useContext(SearchContext);
  const [querySortByState, setQuerySortByState] = useState<SortingRule[]>(querySortBy);
  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] = useReducer(reducer, {
    queryPageIndex: 0,
    queryPageSize: queryPageSizeProps ?? 10,
    totalCount: null,
  });
  const [pageIndex, setPageIndex] = useState(0);

  const {
    status,
    isError,
    isFetching,
    isSuccess,
    data: originalData,
  } = useQuery(
    [queryKey, queryPageIndex, queryPageSize, debouncedQuery, querySortByState],
    () => queryFn(queryPageIndex, queryPageSize, debouncedQuery, querySortByState),
    { keepPreviousData: true }
  );

  const data = React.useMemo(() => {
    return isSuccess ? originalData : { data: [], meta: { total: 0 } };
  }, [originalData]);

  const isLoading = status === "loading" || isFetching;
  const pageCount = Math.ceil(data.meta.total / 10);
  const hasActions = onEdit || onDelete || onDetails;

  const gotoPage = (i: number) => {
    setPageIndex(i);
  };

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    if (data?.meta?.total) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.meta.total,
      });
    }
  }, [data?.meta?.total]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "relative",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader my={"5rem"} color="bright-green" />
        </div>
      ) : (
        <>
          <Accordion chevronPosition="right" variant="contained" mb={"md"} 
            styles={(theme) => ({
              item: {
                backgroundColor: '#fff',
                borderColor: theme.colors['custom-gray'][7],
                "&:hover": {
                  backgroundColor: '#FCFCFC',
                },
                '&[data-active]': {
                  backgroundColor: '#FCFCFC',
                },
              },
              label: {
                color: '#484948',
              },
              control: {
                "&:hover": {
                  backgroundColor: '#FCFCFC',
                }
              },
              chevron: {
                color: '#289323',
                transform: 'scale(1.3)',
                '&[data-rotate]': {
                  transform: 'scale(1.3) rotate(180deg)',
                }
              }
            })}
          >
            {data.data.map((row: T, i: number) => {
              return (
                <Accordion.Item value={i.toString()} key={i}>
                  <Center>
                    <Accordion.Control {...(!detailsRenderer && { chevron: <></> })}>
                      {labelRenderer && labelRenderer(row)}
                    </Accordion.Control>
                    {hasActions && (
                      <Group m={"md"}>
                        {onDetails && (
                          <Button 
                            styles={(theme) => ({
                              root: {
                                backgroundColor: '#69B365',
                                height: 37,
                                '&:hover': {
                                  backgroundColor: "#1C7B18",
                                },
                              },
                            })} 
                              onClick={() => onDetails(row)}>
                            <Eye size={22} />
                          </Button>
                        )}
                        {onEdit && (
                          <Button 
                          styles={(theme) => ({
                            root: {
                              backgroundColor: '#69B365',
                              height: 37,
                              '&:hover': {
                                backgroundColor: "#1C7B18",
                              },
                            },
                          })}  onClick={() => onEdit(row)}>
                            <Pencil size={22} />
                          </Button>
                        )}
                        {onDelete && (
                          <Button 
                            styles={(theme) => ({
                              root: {
                                backgroundColor: '#C5043F',
                                height: 37,
                                '&:hover': {
                                  backgroundColor: "#A70637",
                                },
                              },
                          })}  onClick={() => onDelete(row)}>
                            <Trash size={22} />
                          </Button>
                        )}
                      </Group>
                    )}
                  </Center>
                  {detailsRenderer && <Accordion.Panel>{detailsRenderer(row)}</Accordion.Panel>}
                </Accordion.Item>
              );
            })}
          </Accordion>
          {!isError && data.data.length > 0 && (
            <Pagination
              style={{ justifyContent: "center" }}
              page={pageIndex + 1}
              onChange={(page) => !isLoading && gotoPage(page - 1)}
              total={pageCount}
              styles={(theme) => ({
                item: {
                  color: "#484948",
                  borderColor: theme.colors['custom-gray'][7],
                  '&[data-active]': {
                    backgroundColor: "#6AB366",
                  },
                  '&:hover': {
                    borderColor: theme.colors['bright-green'][0],
                  },
                  '& svg': {
                    color: '#6AB366',
                    transform: 'scale(1.3)',
                  },
                  '&[disabled] svg': {
                    color: theme.colors['custom-gray'][7],
                  },
                },
              })}
            />
          )}
          {!isError && data.data.length === 0 && <Text>No results were found.</Text>}
        </>
      )}
    </>
  );
}

export default FancyList;
