import {API_URL, FETCH_HEADERS} from "../constants";
import dayjs from "dayjs";
import {BunkerRequest, SortingRule} from "../../types";
import {generateSortString, handleRequestErrors} from "../tools";

export const fetchBunkerRequests = async (page: number, pageSize: number = 10, query: string = "", sortBy: SortingRule[] = []) => {
    return await fetch(`${API_URL}/bunker-request?page=${page + 1}&limit=${pageSize}${generateSortString(sortBy)}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchBunkerRequest = async (id?: string) => {
    return await fetch(`${API_URL}/bunker-request/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function createBunkerRequest(bunkerRequest: BunkerRequest) {
    return await fetch(`${API_URL}/bunker-request`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify({
            ...bunkerRequest,
            date: dayjs(bunkerRequest.date).format("YYYY-MM-DD")
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

type DeleteBunkerRequestProps = {
    id: string | number;
    reason?: string;
}

export async function deleteBunkerRequest(props: DeleteBunkerRequestProps) {
    return await fetch(`${API_URL}/bunker-request/${props.id}`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
        body: JSON.stringify({
            reason: props.reason
        })
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}
