import {API_URL, FETCH_HEADERS} from "../constants";
import dayjs from "dayjs";
import {handleRequestErrors} from "../tools";

export const fetchTimeSlots = async (date: (Date | null) = null, locationId: string) => {
    let formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : "";

    return await fetch(`${API_URL}/time-slot?location_id=${locationId}&date=${formattedDate}`, {
        ...FETCH_HEADERS(),
    })
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchTimeSlot = async (id?: string) => {
    return await fetch(`${API_URL}/time-slot/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};
