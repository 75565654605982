import {Container, createStyles, Loader, Text, Title} from '@mantine/core'
import CreateBunkerRequestForm from "../../components/forms/CreateBunkerRequestForm";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import useLocationData from "../../hooks/location/useLocationData";
import {showNotification} from "@mantine/notifications";
import {BunkerRequest, PatchableBunkerRequest} from "../../../types";
import {Check} from "tabler-icons-react";
import useMutateBunkerRequestData from "../../hooks/bunkerRequest/useMutateBunkerRequestData";
import {createBunkerRequest} from "../../api/bunkerRequests";

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 60,
        paddingBottom: 20,
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

export default function CreateBunkerRequest() {
    const {classes} = useStyles();
    const {id: locationId} = useParams();
    const navigate = useNavigate();

    const {status, data, isFetching, isError} = useLocationData(locationId);
    const isLoading = status === "loading" || isFetching;

    const onSuccess = (values: BunkerRequest) => {
        showNotification({
            title: 'Bunker request created',
            icon: <Check/>,
            message: `Bunker request has been created!`,
        })

        values.id && navigate(`/request/${values.id}/confirmation`)
    }

    const onError = (error: string) => {
        showNotification({
            title: `Something went wrong`,
            message: `An error occurred while creating the bunker request. ${error}`,
            color: 'red'
        })
    }

    const {mutate, isLoading: isMutating} = useMutateBunkerRequestData(createBunkerRequest, onSuccess, onError);

    const onSubmit = (values: PatchableBunkerRequest) => {
        mutate(values)
    }

    if ((!isLoading && !data) || isError) {
        return <Navigate replace to={"/"}/>
    }

    return (
        <Container className={classes.root}>
            <Container pt={18}>
                <Title mb={"lg"}>Bunker request</Title>
                <Text mb={"lg"} weight={700}>Create a bunker request.</Text>
            </Container>

            {isLoading ? (
                <Container>
                    <Loader color="bright-green"/>
                </Container>
            ) : (
                <CreateBunkerRequestForm
                    locationId={data.id}
                    onSubmit={onSubmit}
                    isLoading={isMutating}
                />
            )}
        </Container>
    );
}