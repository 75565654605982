import React, {createContext, ReactNode, useState} from "react";

type Props = {
    children?: ReactNode;
}

type FooterContextProps = {
    shown: boolean,
    setShown: (value: boolean) => void,
}

export const FooterContext = createContext<FooterContextProps>({
    shown: true,
    setShown: () => {},
});

export default function FooterProvider(props: Props) {
    const [shown, setShown] = useState(true);

    return (
        <FooterContext.Provider value={{shown, setShown}}>
            {props.children}
        </FooterContext.Provider>
    );
}