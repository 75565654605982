import React, {useContext} from 'react';
import {Container, Stack, Text, TextInput, Title} from "@mantine/core";
import FancyList from "../../components/FancyList";
import {Location} from "../../../types";
import {fetchLocations} from "../../api/locations";
import {SearchContext} from "../../providers/search";
import {useNavigate} from "react-router-dom";
import {Map2} from "tabler-icons-react";

function MapIconOverlay() {
    const navigate = useNavigate()

    return (
        <div style={{
            width: "100%",
            display: "flex",
            position: "absolute",
            justifyContent: "right",
        }} >
            <div
                style={{position: "fixed", marginTop: 20, marginRight: 20, zIndex: 5}}
                onClick={() => navigate("/")}
            >
                <Map2 size={28}/>
            </div>
        </div>
    );
}

const Locations = () => {
    const {query, setQuery} = useContext(SearchContext);
    const navigate = useNavigate()

    const labelRenderer = (location: Location) => {
        return (
            <Stack spacing={0}>
                <Text>{location.name}</Text>
                <Text size="sm" color="#8C9196" weight={400}>
                    {location.location}
                </Text>
            </Stack>
        )
    }

    const onClick = (location: Location) => {
        navigate("/", {state: {location: location}})
    }

    return (
        <>
            <MapIconOverlay/>

            <Container style={{paddingTop: 78}}>
                <Title mb={"md"}>Locations</Title>

                <TextInput  
                    styles={(theme) => ({
                        input: {
                            '&::placeholder': {
                                color: theme.colors['custom-gray'][8],
                            },
                            borderColor: theme.colors['custom-gray'][7],
                            '&:focus': {
                              borderColor: theme.colors['bright-green'][0],
                            },
                            color: '#484948',
                        },
                      })} 
                    mb={"md"} placeholder={"Search..."} value={query}
                           onChange={(event) => setQuery(event.currentTarget.value)}/>

                <FancyList<Location>
                    queryKey={'locations'}
                    queryFn={fetchLocations}
                    labelRenderer={labelRenderer}
                    onDetails={onClick}
                />
            </Container>
        </>
    );
};

export default Locations;
