import {
  Button,
  Container,
  Group,
  Indicator,
  Input,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { Calendar } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { PatchableBunkerRequest, TimeSlotException } from "../../../types";
import { fetchTimeSlotExceptions } from "../../api/timeSlotExceptions";
import { validateDate, validateNumber, validateString } from "../../validator";
import BunkerRequestConfirmation from "../BunkerRequestConfirmation";
import { ShipSelect } from "../selects/ShipSelect";
import { TimeSlotSelect } from "../selects/TimeSlotSelect";

type CreateBunkerRequestFormProps = {
  onSubmit: (values: any) => void;
  locationId: string;
  isLoading?: boolean;
};

export default function CreateBunkerRequestForm(props: CreateBunkerRequestFormProps) {
  const { onSubmit, locationId, isLoading = false } = props;
  const largeScreen = useMediaQuery("(min-width: 576px)");
  const [proceed, handlers] = useDisclosure(false);

  const [data, setData] = useState<TimeSlotException[]>([]);

  const minDate = dayjs().add(1, "day").startOf("day").toDate();

  const form = useForm<PatchableBunkerRequest>({
    initialValues: {
      id: "",
      date: minDate,
      expected_amount: "",
      time_slot_id: "",
      ship_id: "",
    },

    validate: {
      date: (value: Date) => validateDate(value),
      expected_amount: (value) => validateNumber(value),
      time_slot_id: (value) => validateString(value),
      ship_id: (value) => validateString(value),
    },
  });

  const onProceed = () => handlers.toggle();

  // Excluded dates are dates that have no start_time or end_time,
  // If they have no start_time or end_time, they are disabled for the entirety of the day
  const excludedDates = data
    ?.filter((item: TimeSlotException) => !item.start_time || !item.end_time)
    ?.map((item: TimeSlotException) => new Date(item.date));

  const warningDates = data
    ?.filter((item: TimeSlotException) => item.start_time && item.end_time)
    ?.map((item: TimeSlotException) => new Date(item.date));

  const isDateExcluded = (date: Date) => {
    return !excludedDates
      ? false
      : !!excludedDates?.find(
          (dateException: Date) => dateException.toDateString() === date.toDateString()
        );
  };

  const isDateWarning = (date: Date) => {
    return !warningDates
      ? false
      : !!warningDates?.find(
          (dateException: Date) => dateException.toDateString() === date.toDateString()
        );
  };

  /**
   * Retrieve all time slot exceptions for the selected location.
   */
  const onLoad = async () => {
    const data = await fetchTimeSlotExceptions(locationId);

    if (!data) {
      return;
    }

    setData(data);
  };

  useEffect(() => {
    const time = form.values.date?.getTime();
    if (time) {
      form.setFieldValue("time_slot_id", "");
    }
  }, [form.values.date?.getTime()]);

  /**
   * Fetch on mount.
   */
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <form onSubmit={form.onSubmit(onProceed)}>
      {!proceed ? (
        <Container>
          <Input.Wrapper mb={"sm"} required label={"Pick a date"} 
            styles={(theme) => ({
                label: {
                    color: "#484948",
                },
                required:{
                    color: "#004F65"
                }
            })}>
            <div style={{ position: "relative" }}>
              <LoadingOverlay visible={!excludedDates} />

              <Calendar
                fullWidth={!largeScreen}
                required
                minDate={minDate}
                excludeDate={!excludedDates ? undefined : (date) => isDateExcluded(date)}
                initialMonth={form.values.date}
                allowLevelChange={false}
                renderDay={(date) => (
                  <Indicator
                    offset={8}
                    title={
                      isDateExcluded(date)
                        ? "Time slot exception active"
                        : "Limited time slot exception active"
                    }
                    color={isDateExcluded(date) ? "red" : "orange"}
                    disabled={!isDateExcluded(date) && !isDateWarning(date)}
                    zIndex={0}
                  >
                    {date.getDate()}
                  </Indicator>
                )}
                styles={(theme) => ({
                  calendarHeaderLevel: {
                    color: "#484948",
                  },
                  day: {
                    color: "#484948",
                    '&:hover': {
                      backgroundColor: theme.colors['bright-green'][0],
                      color: theme.white,
                    },
                  },
                  
                })}
                {...form.getInputProps("date")}
              />
            </div>
          </Input.Wrapper>

          <Group mb={"sm"} grow>
            <TextInput
              type={"number"}
              required
              min={1}
              label={"Expected amount"}
              description={"In kilograms"}
              {...form.getInputProps("expected_amount")}
              styles={(theme) => ({
                  input: {
                      '&::placeholder': {
                          color: theme.colors['custom-gray'][8],
                      },
                      borderColor: theme.colors['custom-gray'][7],
                      '&:focus': {
                      borderColor: theme.colors['bright-green'][0],
                      },
                      color: '#484948',
                  },
                  label: {
                      color: "#484948",
                  },
                  description: {
                      color: "#8C9196"
                  },
                  required:{
                      color: "#004F65"
                  }
              })}
            />
          </Group>

          <ShipSelect
            required
            pb={"sm"}
            defaultValue={form.values.ship_id}
            onClear={() => form.setFieldValue("ship_id", "")}
            onItemSubmit={(value) => form.setFieldValue("ship_id", value.ship.id)}
            error={form.errors.ship_id ?? null}
          />

          <TimeSlotSelect
            required={true}
            disabled={!form.values.date || !locationId}
            location={locationId}
            date={form.values.date ?? new Date()}
            pb={"sm"}
            error={form.errors.time_slot_id ?? null}
            onChange={(value) => form.setFieldValue("time_slot_id", value ?? "")}
            value={form.values.time_slot_id}
          />

          <Button fullWidth loading={isLoading} type={"submit"} size="lg">
            Proceed
          </Button>
        </Container>
      ) : (
        <>
          <BunkerRequestConfirmation
            onCancel={() => handlers.toggle()}
            bunkerRequest={form.values}
          />

          <Group grow>
            <Button
              mt={"md"}
              disabled={isLoading}
              variant={"outline"}
              onClick={() => handlers.toggle()}
              size="lg"
            >
              Go back
            </Button>

            <Button mt={"md"} loading={isLoading} onClick={() => onSubmit(form.values)} size="lg">
              Submit bunker request
            </Button>
          </Group>
        </>
      )}
    </form>
  );
}
