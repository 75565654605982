import OnboardingCarousel from "../components/carousels/OnboardingCarousel";
import {useContext, useEffect} from "react";
import {FooterContext} from "../providers/footer";
import {Group, ActionIcon, Container} from "@mantine/core";
import useOnboarding from "../hooks/useOnboarding";
import {NavbarButtonContext} from "../providers/navbarButton";
import BrandHeader from "../components/headers/BrandHeader";

export default function Onboarding() {
    const {setShown: setFooterShown} = useContext(FooterContext);
    const {setShown: setNavbarButtonShown} = useContext(NavbarButtonContext);
    const {boardingCompleted} = useOnboarding();

    /**
     * Hide the footer and navbar button on mount.
     */
    useEffect(() => {
        setFooterShown(false);
        setNavbarButtonShown(false);
    }, []);

    return (
        <>
            <Group position="center">
                <BrandHeader />

                <div style={{position: "absolute", marginTop: "20px", right: "15px"}}>
                    <ActionIcon
                        variant={"transparent"}
                        color="custom-red"
                        size="lg"
                        onClick={boardingCompleted}
                    >
                        Skip
                    </ActionIcon>
                </div>
            </Group>

            <Container mt={40}>
                <OnboardingCarousel />
            </Container>

        </>
    );
}