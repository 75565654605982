import {FETCH_HEADERS, API_URL} from "../constants";
import {handleRequestErrors} from "../tools";

export const fetchNotifications = async (page: number, pageSize: number = 10) => {
    return await fetch(`${API_URL}/notification?page=${page + 1}&limit=${pageSize}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export async function deleteNotification(id: string) {
    return await fetch(`${API_URL}/notification/${id}`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}
