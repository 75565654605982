import {API_URL, FETCH_HEADERS} from "../constants";
import {useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import {Password} from "../../types";
import {showNotification} from "@mantine/notifications";
import {formatErrors, isEmptyArray} from "../tools";

export default function useResetPassword() {
    const {token, email, expires, signature} = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchData = async function(values: Password) {
        return await fetch(`${API_URL}/auth/password-reset`, {
            method: "POST",
            body: JSON.stringify({
                token: token,
                email: email,
                expires: expires,
                signature: signature,
                ...values,
            }),
            ...FETCH_HEADERS(),
        }).then((d) => d.json());
    }

    const resetPassword = async function(values: Password) {
        setLoading(true);
        const response = await fetchData(values);
        const errors = formatErrors(response);
        setLoading(false);

        if (!response?.data) {
            showNotification({
                title: `Something went wrong`,
                message: isEmptyArray(errors) ? 'Your password could not be changed.' : errors[0],
                color: 'red'
            });

            return;
        }

        showNotification({
            title: `Password changed`,
            message: 'Your password has been changed! You may now login.',
        });

        navigate('/login');
    }

    return {
        resetPassword,
        loading,
    }
}